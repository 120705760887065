<template>
  <div style="display: inline">
    <!--
      width: 100%; height: auto; text-align: right;
     -->
    <!--  <b-icon-search v-if="!showForm"></b-icon-search> -->

    <!-- <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner> -->

    <form class="form-inline" @submit.prevent="submitHandler">
      <div class="input-group mb-2 mr-sm-2">
        <select class="form-control col-form-label-sm" v-model="searchType" :class="{ 'is-invalid': $v.searchType.$error }" @change.prevent="selectedSearchType">
          <option selected>Select...</option>
          <option value="ref">Reference ID</option>
          <option value="subject">Subject keyword(s)</option>
          <!-- <option value="date">Date</option> -->
          <option value="category" v-if="userRole !== 'customer'">Category</option>
          <option value="name" v-if="userRole !== 'customer'">Customer Name</option>
          <option value="email" v-if="userRole !== 'customer'">Customer Email</option>
          <option value="daterange">Date Range</option>
        </select>
      </div>

      <div class="input-group mb-2 mr-sm-2" v-show="searchTermInput">
        <input v-model="searchTerm" type="text" class="form-control col-form-label-sm" :placeholder="placeholder" />
      </div>

      <!-- Date range -->
      <div class="input-group mb-2 mr-sm-2" v-show="dateRangeInput">
        <RangedatePicker @selected="selectedDateRange" i18n="EN" />
      </div>

      <!-- Categories -->
      <div class="input-group mb-2 mr-sm-2" v-show="categoryInput">
        <select class="form-control col-form-label-sm" style="width:170px" @change.prevent="selectedCategory">
          <option selected>Select...</option>
          <option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </select>
      </div>

      <!-- status -->
      <div class="input-group mb-2 mr-sm-2" v-if="userRole !== 'customer'">
        <select class="form-control col-form-label-sm" v-model="ticketStatusID">
          <option selected>Select status...</option>
          <option v-for="s in ticketStatuses" :value="s.id">{{ s.name }}</option>
        </select>
      </div>

      <button type="submit" class="btn btn-sm btn-outline-dark mb-2 ">
        <b-spinner v-if="spinner" small variant="dark" label="Spinning"></b-spinner>
        <span v-else>Search</span>
      </button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import RangedatePicker from 'vue-rangedate-picker'
import ISO8601Date from '../helper/ISO8601Date'

export default {
  name: 'SearchForm',

  data() {
    return {
      showForm: true,
      spinner: false,
      searchType: 'Select category...',
      searchTerm: '',
      categoryID: '',
      ticketStatusID: '',
      placeholder: 'Search term...',
      // For Date range
      searchTermInput: true,
      dateRangeInput: false,
      categoryInput: false,
      startDate: '',
      endDate: '',
      // for search ready
      categories: [],
      ticketStatuses: []
    }
  },

  props: {
    userRole: { type: String, required: true },
    paginationParams: { type: Object, required: true }
  },

  mounted() {
    //this.spinner = true;
    axios.get(`/${this.userRole}/for-search`).then(res => {
      if (res.data.status == 'success') {
        this.categories = res.data.categories
        this.ticketStatuses = res.data.ticketStatuses
      }
      this.spinner = false
    })
  },

  methods: {
    submitHandler() {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      // let formData = new FormData();
      let searchObj = {
        searchType: this.searchType,
        searchTerm: this.searchTerm,
        ticketStatusID: this.ticketStatusID,
        categoryID: this.categoryID,
        // For Date range
        startDate: this.startDate,
        endDate: this.endDate
      }
      let jStr = JSON.stringify(searchObj)
      window.localStorage.setItem('sobj', jStr)
      searchObj = { ...searchObj, ...this.paginationParams }

      axios
        .get(`/${this.userRole}/tickets-search`, {
          params: searchObj
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.$v.$reset()
            this.$emit('searchTickets', res.data)
          } else {
            this.$swal('Oops!', res.data.message, 'error')
          }
          this.spinner = false
        })
    },

    selectedSearchType(e) {
      this.$emit('paginationReset')
      // actual search type
      this.searchTerm = ''
      this.searchTermInput = true
      this.dateRangeInput = false
      this.categoryInput = false

      let cat = e.target.value
      if (cat == 'Select...') {
        return
      } else if (cat == 'ref') {
        this.placeholder = 'Enter reference ID'
      } else if (cat == 'subject') {
        this.placeholder = 'Enter subject here'
      } else if (cat == 'name') {
        this.placeholder = "Enter customer's name "
      } else if (cat == 'email') {
        this.placeholder = "Enter customer's email "
      } else if (cat == 'category') {
        this.categoryInput = true
        this.searchTermInput = false
      } else if (cat == 'daterange') {
        this.dateRangeInput = true
        this.searchTermInput = false
      } else {
        this.placeholder = 'Search term...'
      }
    },

    selectedDateRange(v) {
      this.$emit('paginationReset')
      this.startDate = ISO8601Date(v.start)
      this.endDate = ISO8601Date(v.end)
    },

    selectedCategory(e) {
      this.$emit('paginationReset')
      let categoryID = e.target.value
      this.categoryID = categoryID
    }
  },

  validations: {
    searchType: { required }
    //searchTerm: { required }
  },

  components: { RangedatePicker }
}
</script>

<style></style>
