<template>
  <span>
    <button v-if="ticket.status.slug != 'resolved'" class="btn btn-sm btn-primary m-2" v-b-modal="modalID">
      {{ assignTitle }}
    </button>
    <!-- Assign or ReAssign Modal -->
    <b-modal :id="modalID" :ref="modalID" :title="`${assignTitle} ticket to a department`" size="md" hide-footer no-close-on-backdrop>
      <form @submit.prevent="assign">
        <!-- Department -->
        <div class="form-group">
          <label for="department">Select a department</label>
          <select class="form-control" v-model="departmentUUID" :class="{ 'is-invalid': $v.departmentUUID.$error, 'is-valid': !$v.departmentUUID.$invalid }">
            <option placeholder="Select..." selected>Select...</option>
            <option v-for="(dept, i) in departments" :key="i" :value="dept.uuid">{{ dept.name }}</option>
          </select>
          <div class="invalid-feedback">
            <span v-if="!$v.departmentUUID.required">Select a department.</span>
          </div>
        </div>

        <!-- Comment -->
        <div class="form-group">
          <label for="comment">Add a comment</label>
          <textarea class="form-control" v-model="comment" :class="{ 'is-invalid': $v.comment.$error, 'is-valid': !$v.comment.$invalid }" />
          <div class="invalid-feedback">
            <span v-if="!$v.comment.required">Comment required.</span>
          </div>
        </div>

        <button v-if="!spinner" class="btn btn-primary" type="submit">{{ assignTitle }}</button>
        <b-spinner v-if="spinner" variant="primary" label="Spinning"></b-spinner>
      </form>
    </b-modal>
    <!-- END Assign or ReAssign Modal -->
  </span>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AssignModal',

  props: {
    path: String,
    ticket: Object,
    departments: Array
  },

  data() {
    return {
      modalID: 'assignModal',
      comment: '',
      departmentUUID: '',
      spinner: false
    }
  },

  validations: {
    comment: { required },
    departmentUUID: { required }
  },

  methods: {
    assign() {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      let formData = new FormData()
      formData.append('status', 'assigned')
      formData.append('ref', this.ticket.ref)
      formData.append('comment', this.comment)
      formData.append('departmentUUID', this.departmentUUID)
      formData.append('originURL', window.location.origin)
      axios.post('/change-ticket-status', formData).then(res => {
        if (res.data.status == 'success') {
          //this.ticket.status = res.data.newStatus;
          this.comment = ''
          this.individualUUID = ''
          this.$refs[this.modalID].hide()
          this.$emit('newStatus', { newStatus: res.data.newStatus, newStatusLog: res.data.newStatusLog })
          this.$swal('Done!', res.data.message, 'success')
        } else {
          this.$swal('Oops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  },

  computed: {
    assignTitle() {
      let t = 'Assign (Dept)'
      if (this.ticket.status.slug == 'assigned') {
        t = 'Re-Assign (Dept)'
      }
      return t
    }
  }
}
</script>

<style></style>
