<template>
  <div id="UserLayout">
    <b-navbar toggleable="lg" type="light" variant="light">
      <!-- Logo -->
      <router-link :to="{ name: 'HomeUser' }" class="navbar-brand text-muted">
        <img alt="UPL Logo" src="../assets/upl-logo.jpg" style="height: 50px" height="50" width="66.66" />
        &nbsp;&nbsp; Customer Support
      </router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <li class="nav-item">
            <router-link :to="{ name: 'HomeUser' }" class="nav-link">
              <b-icon-house></b-icon-house>
              Home
            </router-link>
          </li>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template #button-content> <b-icon-person-circle></b-icon-person-circle> {{ authUser.Name }} </template>
            <li>
              <router-link :to="{ name: 'ProfileUser' }" class="dropdown-item">
                <b-icon-person></b-icon-person>
                Profile
              </router-link>
            </li>
            <Logout kind="list" />
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view />
  </div>
</template>

<script>
import Logout from "@/components/Logout.vue";

export default {
  name: "UserLayout",

  components: {
    Logout,
  },

  computed: {
    authUser() {
      return this.$store.state.authUser;
    },

    year() {
      let d = new Date();
      return d.getFullYear();
    },
  },
};
</script>
