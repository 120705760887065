<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-10 offset-lg-1 col-sm-12">
        <div class="card mb-3">
          <div class="card-header"><BackIcon /><b-icon-sticky v-if="!$routerHistory.hasPrevious()"></b-icon-sticky> Open New Ticket</div>
          <div class="card-body">
            <p>
              Please provide your name, email, and phone. Select a category, subcategory and priority, enter your subject and message. <br />
              Please note that all fields are mandatory
            </p>

            <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" v-model="name" class="form-control" placeholder="e.g. John Smith" :class="{ 'is-invalid': $v.name.$error, 'is-valid': !$v.name.$invalid }" />
                    <div class="invalid-feedback">
                      <span v-if="!$v.name.required">Name is required.</span>
                      <span v-if="!$v.name.minLength">Name must be at least {{ $v.name.$params.minLength.min }} letters.</span>
                      <span v-if="!$v.name.maxLength">Name must be at most {{ $v.name.$params.maxLength.max }} letters.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="verifyEmail">Email</label>
                    <input type="text" v-model="email" class="form-control" placeholder="e.g. john@gmail.com" :class="{ 'is-invalid': $v.email.$error, 'is-valid': !$v.email.$invalid }" />
                    <div class="invalid-feedback">
                      <span v-if="!$v.email.required">Email is required.</span>
                      <span v-if="!$v.email.email">Email must be valid email address.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="phone">Phone number</label>
                    <input type="text" v-model="phone" class="form-control" placeholder="e.g. 08011223344" :class="{ 'is-invalid': $v.phone.$error, 'is-valid': !$v.phone.$invalid }" />
                    <div class="invalid-feedback">
                      <span v-if="!$v.phone.required">Phone is required.</span>
                      <span v-if="!$v.phone.numeric">Phone must be numeric.</span>
                      <span v-if="!$v.phone.minLength">Phone must be at least {{ $v.phone.$params.minLength.min }} digits.</span>
                      <span v-if="!$v.phone.maxLength">Phone must be at most {{ $v.phone.$params.maxLength.max }} digits.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <!-- Category of Issues -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="category">Category of issues</label>
                    <select @change="topSelected($event)" class="form-control" :class="{ 'is-invalid': $v.category.$error, 'is-valid': !$v.category.$invalid }">
                      <option value="Select..." selected>Select...</option>
                      <option v-for="(item, index) in categories" :key="index" :value="index">{{ item.name }}</option>
                    </select>
                    <div class="invalid-feedback">
                      <span v-if="!$v.category.required">Category of issues is required.</span>
                    </div>
                  </div>
                </div>
                <!-- Subcategory of issues -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="subCategory">Subcategory</label>
                    <select class="form-control" v-model="subCategory" :class="{ 'is-invalid': $v.subCategory.$error, 'is-valid': !$v.subCategory.$invalid }">
                      <option v-for="(item, index) in subCategories" :key="index" :value="item.uuid">{{ item.name }}</option>
                    </select>
                    <div class="invalid-feedback">
                      <span v-if="!$v.subCategory.required">Subcategory of issues is required.</span>
                    </div>
                  </div>
                </div>
                <!-- priority -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="subCategory">Priority</label>
                    <select class="form-control" v-model="priority">
                      <option value="low" selected>Low</option>
                      <option value="medium">Medium</option>
                      <option value="high">High</option>
                      <option value="urgent">Urgent</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="subject">Subject</label>
                    <input type="text" v-model="subject" class="form-control" placeholder="e.g. My subject" :class="{ 'is-invalid': $v.subject.$error, 'is-valid': !$v.subject.$invalid }" />
                    <div class="invalid-feedback">
                      <span v-if="!$v.subject.required">Subject is required.</span>
                      <span v-if="!$v.subject.minLength">Subject must be at least {{ $v.subject.$params.minLength.min }} letters.</span>
                      <span v-if="!$v.subject.maxLength">Subject must be at most {{ $v.subject.$params.maxLength.max }} letters.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="subject">CC</label> <small style="font-size:.65rem"> - comma separated. e.g. one@gmail.com, two@gmail.com etc</small>
                    <input type="text" v-model="ccs" class="form-control" placeholder="" />
                  </div>
                </div>
                <!--  -->
              </div>

              <div class="form-group">
                <label for="message">Message</label>
                <vue-editor v-model="message" :editorToolbar="editorToolbar"></vue-editor>
                <div class="text-danger text-xs" v-show="$v.message.$error">
                  <span v-if="!$v.message.required">Message is required.</span>
                  <span v-if="!$v.message.minLength">Message must be at least {{ $v.message.$params.minLength.min }} letters.</span>
                </div>
              </div>

              <div class="form-group">
                <b-icon-paperclip></b-icon-paperclip> <label for="att">Attachment(s)</label>
                <vue-dropzone id="dropzone" ref="myVueDropzone" :useCustomSlot="true" :options="dropzoneOptions" @vdropzone-error="uploadError" @vdropzone-success="uploadSuccess" @vdropzone-file-added="uploadFileAdded">
                  <div class="dropzone-custom-content" v-html="dropzoneOptions.myCustomContent"></div>
                </vue-dropzone>
              </div>

              <button v-if="!spinner" class="btn btn-warning" type="submit"><i class="far fa-paper-plane mr-1"></i> Submit</button>
              <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>

              <!--  -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
import { required, email, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import BackIcon from '@/components/BackIcon.vue'
import vue2Dropzone from 'vue2-dropzone'
import dropzoneOptions from '../helper/dropzoneOptions'
import ccJsonString from '../helper/ccs'

export default {
  name: 'TicketCreate',

  data() {
    return {
      //showSubCat: false,
      spinner: false,
      categories: [],
      subCategories: [],

      /******** Form data *********/
      // First row
      name: '',
      email: '',
      phone: '',
      // Second row
      category: '',
      subCategory: '',
      priority: 'low',
      // Subject and Message
      subject: '',
      message: '',
      attachments: [],
      ccs: '',

      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    }
  },

  mounted() {
    //this.spinner = true;
    axios.get('/categories').then(res => {
      if (res.data.status == 'success') {
        this.categories = res.data.categories
      }
      this.spinner = false
    })
  },

  methods: {
    handleSubmit(e) {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      let formData = new FormData()

      // first row
      formData.append('name', this.name)
      formData.append('email', this.email)
      formData.append('phone', this.phone)
      // second row
      formData.append('topCategoryID', this.category)
      formData.append('subCategoryID', this.subCategory)
      formData.append('priority', this.priority)
      // submit and message
      formData.append('subject', this.subject)
      formData.append('message', this.message)
      // others
      formData.append('source', 'web')
      formData.append('originURL', window.location.origin)
      formData.append('attachments', JSON.stringify(this.attachments))
      formData.append('ccs', ccJsonString(this.ccs))

      //console.log(formData);

      axios
        .post('/ticket/create', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.$v.$reset()
            //empty form
            this.name = ''
            this.email = ''
            this.phone = ''
            this.category = ''
            this.subCategory = ''
            this.subject = ''
            this.message = ''
            this.attachments = []
            this.$refs.myVueDropzone.removeAllFiles()
            // fee back
            this.$swal(res.data.title, res.data.message, 'success')
            this.$router.push({ name: 'TicketDetails', params: { ref: res.data.ticketID } })
          } else {
            this.$swal('Oops!', res.data.message, 'error')
          }
          this.spinner = false
        })
        .catch(err => {
          this.spinner = false
        })
    },

    topSelected(e) {
      if (e.target.value == 'Select...') {
        //this.showSubCat = false;
        return
      }
      this.subCategories = this.categories[e.target.value].subCategories
      //this.showSubCat = true;
      this.category = this.categories[e.target.value].uuid
    },

    uploadFileAdded(file) {
      this.spinner = true // vdropzone-file-added( file)
    },
    uploadError(file) {
      this.spinner = false // vdropzone-file-added( file)
      this.$swal('Oops!', file.fileName + ' not uploaded.', 'error')
    },
    uploadSuccess(file, response) {
      this.spinner = false //vdropzone-success( file, response)
      this.attachments.push({
        name: response.link,
        size: file.size,
        original_name: file.name
      })
    }
  },

  computed: {
    topCategoryLabel() {
      return [] //this.$store.state.client.topCategoryLabel;
    },
    subCategoryLabel() {
      return [] //this.$store.state.client.subCategoryLabel;
    },
    dropzoneOptions() {
      return dropzoneOptions
    }
  },

  validations: {
    name: { required, minLength: minLength(2), maxLength: maxLength(30) },
    email: { required, email },
    phone: { required, numeric, minLength: minLength(11), maxLength: maxLength(11) },
    category: { required },
    subCategory: { required },
    subject: { required, minLength: minLength(2), maxLength: maxLength(998) },
    message: { required, minLength: minLength(10) }
  },

  components: {
    VueEditor,
    BackIcon,
    vueDropzone: vue2Dropzone
  }
}
</script>

<style scoped>
.help-block {
  font-size: 0.75rem;
}
</style>
