var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid",attrs:{"id":"CategoriesAdmin"}},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header"},[_vm._v("Categories")]),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.categories,"line-numbers":true,"search-options":{
              enabled: true,
              trigger: 'enter',
            },"pagination-options":{
              enabled: true,
              perPage: 50,
            },"sort-options":{
              enabled: true,
              initialSortBy: [{ field: 'name', type: 'asc' }],
            },"styleClass":"vgt-table condensed myGoodTable"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_vm._v(_vm._s(props.row.name))]):_vm._e(),(props.column.field == 'subcategories')?_c('span',[_vm._v(_vm._s(props.row.subcategories.length))]):_vm._e(),(props.column.field == 'description')?_c('span',[_vm._v(_vm._s(props.row.description))]):_vm._e(),(props.column.field == 'edit')?_c('span',[_c('router-link',{staticClass:"btn btn-xs btn-outline-secondary",attrs:{"to":{ name: 'EditCategoryAdmin', params: { uuid: props.row.uuid } },"title":"Update"}},[_vm._v("edit")])],1):_vm._e(),(props.column.field == 'delete')?_c('span',[_c('button',{staticClass:"btn btn-xs btn-outline-secondary",attrs:{"title":"Delete"},on:{"click":function($event){return _vm.deleteCategory(props.row.uuid, props.row.originalIndex)}}},[_vm._v(" delete ")])]):_vm._e()]}}])})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"pageTitle"},[_vm._v("Categories")])])])}]

export { render, staticRenderFns }