<template>
  <span>
    <button v-if="ticket.status.slug !== 'resolved'" class="btn btn-sm btn-secondary m-2" v-b-modal="modalID">Treat</button>
    <!-- Modal -->
    <b-modal :id="modalID" :ref="modalID" :title="`Treat ticket #${ticket.ref}`" size="md" hide-footer no-close-on-backdrop>
      <form @submit.prevent="assign">
        <!-- Comment -->
        <div class="form-group">
          <label for="comment">Add a comment</label>
          <textarea class="form-control" v-model="comment" :class="{ 'is-invalid': $v.comment.$error, 'is-valid': !$v.comment.$invalid }" />
          <div class="invalid-feedback">
            <span v-if="!$v.comment.required">Comment required.</span>
          </div>
        </div>

        <button v-if="!spinner" class="btn btn-secondary" type="submit">Treat</button>
        <b-spinner v-if="spinner" variant="secondary" label="Spinning"></b-spinner>
      </form>
    </b-modal>
  </span>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'TreatedModal',

  props: {
    ticket: Object,
    path: String
  },

  data() {
    return {
      modalID: 'treatedModal',
      comment: '',
      spinner: false
    }
  },

  validations: {
    comment: { required }
  },

  methods: {
    assign() {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      let formData = new FormData()
      formData.append('status', 'treated')
      formData.append('ref', this.ticket.ref)
      formData.append('comment', this.comment)
      formData.append('originURL', window.location.origin)
      axios.post('/change-ticket-status', formData).then(res => {
        if (res.data.status == 'success') {
          this.$refs[this.modalID].hide()
          //this.$emit("newStatus", res.data.newStatus);
          this.$emit('newStatus', { newStatus: res.data.newStatus, newStatusLog: res.data.newStatusLog })
          this.$swal('Done!', res.data.message, 'success')
        } else {
          this.$swal('Oops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  }
}
</script>

<style></style>
