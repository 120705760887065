<template>
  <div id="ticketNextPrev">
    <b-button variant="primary" @click="navigate('prev')" :disabled="current < 2" size="sm" class="mr-3">
      <b-icon-arrow-left></b-icon-arrow-left>
    </b-button>
    <b-button variant="primary" @click="navigate('next')" :disabled="current >= total" size="sm">
      <b-icon-arrow-right></b-icon-arrow-right>
    </b-button>
    <span style="font-size:.7rem">&nbsp;({{ current }}/{{ total }})</span>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    routeName: String,
    status: String
  },

  data() {
    return {
      diableNext: false,
      diablePrev: false,
      current: 1
    }
  },
  /*  mounted() {
    console.log(this.$route.query.i);
    console.log(this.$store.state.pageTicketList);
  }, */
  methods: {
    async navigate(direction) {
      let ticketList = this.$store.state.pageTicketList
      if (ticketList.length < 1) {
        let path = 'first-level'
        let role = this.$store.state.authUser.Role
        if (role !== 'first-level-support') {
          path = 'second-level'
        }
        await axios.get(`/${path}/tickets?status=${this.status}`).then(res => {
          if (res.data.status == 'success') {
            ticketList = res.data.tickets
            //this.$store.state.pageTicketList = res.data.tickets
          } else {
            //this.$swal("Ooops!", res.data.message, "error");
            return
          }
        })
      }
      //let index = this.$route.query.i;
      // this.$route.params.ref
      this.total = ticketList.length
      let index = ticketList.findIndex(x => x.ref === this.$route.params.ref)

      if (direction == 'prev') {
        index--
      }
      if (direction == 'next') {
        index++
      }

      if (index < 1) {
        // this.diablePrev = true
        index = 0
        this.current = 1
        return
      }

      if (index > ticketList.length - 1) {
        //this.diableNext = true
        index = ticketList.length
        this.current = ticketList.length
        return
      }
      //this.diablePrev = false
      //this.diableNext = false
      this.current = index + 1
      this.$router.push({ name: this.routeName, params: { ref: ticketList[index].ref } })
    }
  },
  computed: {
    total() {
      if (this.$store.state.pageTicketList.length == 0) {
        return 1
      }
      return this.$store.state.pageTicketList.length
    }
  }
}
</script>

<style scoped>
#ticketNextPrev {
  width: 100%;
  height: auto;
  background: none;
  text-align: right;
  margin-bottom: 1rem;
}
</style>
