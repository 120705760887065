let ccs = str => {
  let s1 = str.split(',')
  let s2 = []
  for (let i = 0; i < s1.length; i++) {
    const email = s1[i].replace(/\s+/g, '')
    s2.push({
      name: '',
      email: email
    })
  }
  return JSON.stringify(s2)
}

export default ccs
