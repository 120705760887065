<template>
  <div id="SourceModal" style="display: inline">
    <a href="#" v-b-modal="modalID" title="Change Channel">Source</a>

    <!-- arrow-right -->
    <b-modal :id="modalID" title="Change Channel" size="sm" hide-footer no-close-on-backdrop>
      <form @submit.prevent="submit">
        <!-- channel -->
        <div class="form-group">
          <label for="channel">Select a channel</label>
          <select class="form-control" v-model="channel" :class="{ 'is-invalid': $v.channel.$error, 'is-valid': !$v.channel.$invalid }">
            <option value="Select..." selected>Select...</option>
            <option v-for="(item, index) in channels" :key="index" :value="item.value">{{ item.name }}</option>
          </select>
          <div class="invalid-feedback">
            <span v-if="!$v.channel.required">Channel is required.</span>
          </div>
        </div>

        <button v-if="!spinner" class="btn btn-warning" type="submit"><i class="far fa-paper-plane mr-1"></i> Submit</button>
        <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'SourceModal',

  data() {
    return {
      spinner: false,
      modalID: 'SourceModal',
      channel: '',
      channels: [
        { name: 'Email', value: 'email' },
        { name: 'Phone', value: 'phone' },
        { name: 'WhatsApp', value: 'whatsapp' },
        { name: 'Facebook', value: 'facebook' },
        { name: 'X (Twitter)', value: 'twitter' },
        { name: 'Instagram', value: 'instagram' },
        { name: 'Webchat', value: 'webchat' },
        { name: 'Telegram', value: 'telegram' }
      ]
    }
  },

  props: {
    ticket: { type: Object }
  },

  methods: {
    submit() {
      this.spinner = true

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      let formData = new FormData()
      formData.append('source', this.channel)
      formData.append('ref', this.ticket.ref)

      axios.post('/first-level/change-source', formData).then(res => {
        if (res.data.status == 'success') {
          this.$swal('Channel', res.data.message, 'success')
          this.$emit('sourceChangeSuccess', res.data.newSource)
          this.$bvModal.hide(this.modalID)
        } else {
          this.$swal('Ooops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  },

  computed: {
    client() {
      return this.$store.state.client
    }
  },

  validations: {
    channel: { required }
  }
}
</script>

<style scoped>
.modal-body {
}
.col {
  height: 450px;
  overflow: auto;
  background-color: #eee;
}
.secondCol {
  border-left: 3px solid #ccc;
  border-right: 3px solid #ccc;
}
.list-group {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.escalateButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}
.list-group-item {
  font-size: 0.8rem;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4eadea;
  border-color: #4eadea;
}
</style>
