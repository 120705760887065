<template>
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="display-4">FAQs</h1>
        <p class="lead">Frequently asked questions</p>
        <input type="text" class="form-control" id="search" placeholder="search..." />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <!--  -->
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-3" v-for="(item, index) in faqs" :key="index">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="'accordion-' + index" variant="light" class="accordion-button">
                <b-icon-question-circle class="questionIcon"></b-icon-question-circle>
                <span>{{ item.question }}</span>
              </b-button>
            </b-card-header>
            <b-collapse :id="'accordion-' + index" :visible="index == 0 ? true : false" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-icon-book class="bookIcon"></b-icon-book>
                <div v-html="item.answer"></div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Faq",

  data() {
    return {
      faqs: [],
    };
  },

  mounted() {
    //let loading = this.$loading.show();
    axios.get("/faqs").then((res) => {
      this.faqs = res.data.faqs;
      //loading.hide();
    });
  },
};
</script>



<style scoped>
/*  */

.accordion .card .card-header {
  background-color: #4eadea;
  margin-bottom: -1px;
  padding: 0;
}

.accordion-button {
  background-color: #4eadea;
  font-size: 0.9rem;
  border-radius: 0;
  text-align: left;
  border: none;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.accordion-button:hover {
  background-color: #4eadea;
}
.accordion-button:focus {
  background-color: #fff;
  box-shadow: none;
  border: none;
  background-color: #4eadea;
}

.accordion .card .card-body {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#search {
  padding: 2rem 3rem;
  border-radius: 5rem;
  font-size: 1.5rem;
}

@media (min-width: 200px) {
  .questionIcon {
    font-size: 1rem !important;
    margin-left: 0.5rem;
    margin-right: 1rem;
  }
  .bookIcon {
    font-size: 2.5rem;
    margin-right: 1.5rem;
  }
}

@media (min-width: 768px) {
  .questionIcon {
    font-size: 2rem !important;
    margin-left: 1rem;
    margin-right: 2rem;
  }
  .bookIcon {
    font-size: 5rem;
    margin-right: 3rem;
  }
}
</style>


