<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-header">Knowledge Base</div>
          <div class="card-body">
            <vue-good-table
              :columns="columns"
              :rows="kbs"
              :line-numbers="false"
              :search-options="{
                enabled: true,
                trigger: 'enter'
              }"
              :pagination-options="{
                enabled: false,
                perPage: 50000
              }"
              :sort-options="{
                enabled: true,
                multipleColumns: true
              }"
              styleClass="vgt-table condensed myGoodTable"
            ></vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'KnowledgeBase',

  data() {
    return {
      kbs: []
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios.get('/first-level/knowledge-base').then(res => {
      if (res.data.status == 'success') {
        this.kbs = res.data.kbs
      }
      loading.hide()
    })
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'Title',
          field: 'question',
          width: '30%'
        },
        {
          label: 'Details',
          field: 'answer',
          sortable: false,
          html: true
        }
      ]
      return cols
    }
  }
}
</script>

<style></style>
