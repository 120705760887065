import TicketDetailsCustomer from '../views/customer/TicketDetailsCustomer.vue'
import ChangePasswordCustomer from '../views/ChangePassword.vue'
import TicketsCustomer from '../views/customer/TicketsCustomer.vue'
import TicketCreateCustomer from '../views/customer/TicketCreateCustomer.vue'
import ProfileCustomer from '../views/Profile.vue'
import CustomerLayout from '../layouts/Customer.vue'
import auth from '../helper/auth'

const userRoutes = [
  {
    path: '/customer',
    component: CustomerLayout,
    beforeEnter: auth.ifAuthenticated,
    children: [
      {
        path: '/',
        name: 'HomeCustomer',
        component: TicketsCustomer
      },
      {
        path: 'profile',
        name: 'ProfileCustomer',
        component: ProfileCustomer
      },
      {
        path: 'tickets/:status', // status is sticket state e.g. open, closed, escalated
        name: 'TicketsCustomer',
        component: TicketsCustomer
      },
      {
        path: 'ticket/:ref',
        name: 'TicketDetailsCustomer',
        component: TicketDetailsCustomer
      },
      {
        path: 'create-ticket',
        name: 'TicketCreateCustomer',
        component: TicketCreateCustomer
      },
      {
        path: 'change-password',
        name: 'ChangePasswordCustomer',
        component: ChangePasswordCustomer
      }
      /* {
        path: "user/:uuid",
        name: "UserDetailsFirstLevel",
        component: UserDetailsFirstLevel,
      }, */
    ]
  }
]

export default userRoutes
