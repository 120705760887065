<template>
  <div id="KnowledgeBaseAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Knowledge Base</div>
      </div>
    </div>

    <!--  -->
    <!--  -->
    <div class="row" v-show="showList">
      <div class="col-12">
        <!--  -->
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-3" v-for="(item, index) in kbs" :key="index">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="'accordion-' + index" variant="light" class="accordion-button">
                <b-icon-question-square class="questionIcon"></b-icon-question-square>
                <span>{{ item.question }}</span>
              </b-button>
            </b-card-header>
            <b-collapse :id="'accordion-' + index" :visible="index == 0 ? true : false" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-icon-book class="bookIcon"></b-icon-book>
                <div v-html="item.answer"></div>
                <EditForm :kbIndex="index" :kbObject="item" v-if="editFormIndex == index" @hideBack="hideEditForm"></EditForm>
                <DeleteKnowledgeBase :kbIndex="index" :kbObject="item" v-if="deleteFaqIndex == index" @deleted="hideDeleteKnowledgeBase"></DeleteKnowledgeBase>
              </b-card-body>
              <b-card-footer>
                <button class="btn btn-xs" @click="showEditForm(index)">Edit</button>
                <button class="btn btn-xs" @click="showDeleteKnowledgeBase(index)">Delete</button>
              </b-card-footer>
            </b-collapse>
          </b-card>
        </div>
        <!--  -->
      </div>
    </div>
    <!--  -->
    <!--  -->
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-body">
            <!-- create for -->
            <CreateForm @newKnowledgeBase="newKnowledgeBase"></CreateForm>
            <!-- create for -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import EditForm from './EditForm'
import CreateForm from './CreateForm'
import DeleteKnowledgeBase from './DeleteKnowledgeBase'

export default {
  name: 'KnowledgeBaseAdmin',

  data() {
    return {
      kbs: [],
      showList: false,
      editFormIndex: -1,
      deleteFaqIndex: -1
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios.get('/admin/knowledge-base').then(res => {
      if (res.data.status == 'success') {
        this.kbs = res.data.kbs
        this.showList = true ? res.data.kbs.length > 0 : false
      }
      loading.hide()
    })
  },

  methods: {
    newKnowledgeBase(newKnowledgeBase) {
      this.kbs.push(newKnowledgeBase)
      this.showList = true
    },

    showEditForm(index) {
      this.editFormIndex = index
    },

    hideEditForm(obj) {
      this.editFormIndex = -1
      this.kbs[obj.updatedFaqIndex].answer = obj.updatedFaqObj.answer
      this.kbs[obj.updatedFaqIndex].question = obj.updatedFaqObj.question
    },

    showDeleteKnowledgeBase(index) {
      this.deleteFaqIndex = index
      //console.log(index);
    },

    hideDeleteKnowledgeBase(obj) {
      this.deleteFaqIndex = -1
      this.kbs.splice(obj.deletedFaqIndex, 1)
    }
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    },
    client() {
      return this.$store.state.client
    }
  },

  components: {
    CreateForm,
    DeleteKnowledgeBase,
    EditForm
  }
}
</script>

<style scoped>
/*  */

.accordion .card .card-header {
  background-color: #4eadea;
  margin-bottom: -1px;
  padding: 0;
}

.accordion-button {
  background-color: #4eadea;
  font-size: 0.9rem;
  border-radius: 0;
  text-align: left;
  border: none;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.accordion-button:hover {
  background-color: #4eadea;
}
.accordion-button:focus {
  background-color: #fff;
  box-shadow: none;
  border: none;
  background-color: #4eadea;
}

.accordion .card .card-body {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#search {
  padding: 2rem 3rem;
  border-radius: 5rem;
  font-size: 1.5rem;
}

@media (min-width: 200px) {
  .questionIcon {
    font-size: 1rem !important;
    margin-left: 0.5rem;
    margin-right: 1rem;
  }
  .bookIcon {
    font-size: 2.5rem;
    margin-right: 1.5rem;
  }
}

@media (min-width: 768px) {
  .questionIcon {
    font-size: 2rem !important;
    margin-left: 1rem;
    margin-right: 2rem;
  }
  .bookIcon {
    font-size: 5rem;
    margin-right: 3rem;
  }
}
</style>
