<template>
  <div id="DeleteKnowledgeBase"></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DeleteKnowledgeBaseAdmin',

  props: {
    kbIndex: Number,
    kbObject: {}
  },

  mounted() {
    this.$swal
      .fire({
        title: 'Are you sure?',
        text: 'DELETE: ' + this.kbObject.question,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      })
      .then(result => {
        if (result.value) {
          /* actural action */
          let loading = this.$loading.show()
          let formData = new FormData()
          formData.append('uuid', this.kbObject.uuid)
          axios.post('/admin/knowledge-base/delete', formData).then(res => {
            if (res.data.status == 'success') {
              this.$emit('deleted', { deletedFaqIndex: this.kbIndex })
              this.$swal.fire('Deleted!', res.data.message, 'success')
            } else {
              this.$swal('', res.data.message, 'error')
            }
            loading.hide()
          })
          /* end of actural action */
        }
      })
  }
}
</script>
