<template>
  <div>
    <b-dropdown ref="dropdown">
      <template #button-content>
        <b-icon-filter></b-icon-filter>
      </template>
      <b-dropdown-form>
        <b-form-group label="Select Banks/Merchants" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group v-model="selected" :options="clients" :aria-describedby="ariaDescribedby" name="flavour-2a" stacked></b-form-checkbox-group>
        </b-form-group>
        <b-dropdown-divider></b-dropdown-divider>
        <button class="btn btn-primary btn-block" @click.prevent="apply">Apply</button>
        <b-dropdown-divider></b-dropdown-divider>
        <button class="btn btn-secondary btn-block" @click.prevent="general">General</button>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'FilterDomain',

  props: {
    clients: { type: Array },
    paginationParams: { type: Object, required: true }
  },

  data() {
    return {
      selected: [] // Must be an array reference!
      /*  options: [
        { text: "Access Bank", value: "accessbankplc.com" },
        { text: "Citi", value: "citi.com" },
      ], */
    }
  },

  methods: {
    apply() {
      let searchObj = {
        searchTerm: this.selected
      }
      let jStr = JSON.stringify(searchObj)
      window.localStorage.setItem('sobj', jStr)

      axios
        .get('/first-level/tickets-filter-client', {
          params: {
            searchTerm: JSON.stringify(this.selected)
          }
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.$emit('searchTickets', res.data)
            //console.log(res.data.tickets);
          } else {
            this.$swal('Oops!', res.data.message, 'error')
          }
          this.spinner = false
          this.$refs.dropdown.hide(true)
        })
    },

    general() {
      axios
        .get('/first-level/tickets-filter-general', {
          params: {
            searchTerm: 'generalClients'
          }
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.$emit('searchTickets', res.data)
            //console.log(res.data.tickets);
          } else {
            this.$swal('Oops!', res.data.message, 'error')
          }
          this.spinner = false
          this.$refs.dropdown.hide(true)
        })
    }
  }
}
</script>

<style>
.dropdown-menu {
  min-width: 20rem !important;
}
</style>
