<template>
  <span v-if="ticket.status.slug == 'assigned' || ticket.status.slug == 'open'" id="countDowm">
    <countdown :time="secondsLeft * 1000">
      <template slot-scope="props">Time Remaining：{{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}</template>
    </countdown>
    <!-- <span>Time Remaining：1 days, 23 hours, 59 minutes, 59 seconds.</span> -->
  </span>
</template>

<script>
export default {
  name: "CountDown",

  props: {
    ticket: Object,
  },

  computed: {
    secondsLeft() {
      if (this.ticket.unixTime < 1) {
        return 1;
      }
      const currentTS = Math.floor(Date.now() / 1000);
      const secondInHour = 3600;
      let in24hours = this.ticket.unixTime + secondInHour * 24;
      let in48hours = this.ticket.unixTime + secondInHour * 48;
      let result = in24hours - currentTS;

      if (result < 1) {
        // 24 hours past
        result = in48hours - currentTS;
        //console.log("48 hours one ", result);
        if (result < 1) {
          // 48 hours past
          return 1;
        }
      }
      return result;
    },
  },
};
</script>

<style scoped>
#countDowm {
  font-size: 0.8rem;
  margin-left: 1rem;
}
</style>
