<template>
  <div class="ReplyForm">
    <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
      <div class="form-group">
        <label for="message">
          Message
          <span class="text-danger">*</span>
        </label>
        <vue-editor v-model="message" :editorToolbar="editorToolbar"></vue-editor>
        <div class="text-danger text-xs" v-show="$v.message.$error">
          <span v-if="!$v.message.required">Message is required.</span>
          <span v-if="!$v.message.minLength">Message must be at least {{ $v.message.$params.minLength.min }} letters.</span>
        </div>
      </div>
      <div class="form-group">
        <b-icon-paperclip></b-icon-paperclip> <label for="att">Attachment(s)</label>
        <vue-dropzone id="dropzone" ref="myVueDropzone" :useCustomSlot="true" :options="dropzoneOptions" @vdropzone-error="uploadError" @vdropzone-success="uploadSuccess" @vdropzone-file-added="uploadFileAdded">
          <div class="dropzone-custom-content" v-html="dropzoneOptions.myCustomContent"></div>
        </vue-dropzone>
      </div>

      <button class="btn btn-warning" type="submit" v-if="!spinner">
        <b-icon-reply-fill class="mr-1"></b-icon-reply-fill>
        Reply
      </button>
      <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
import { required, minLength } from 'vuelidate/lib/validators'
import vue2Dropzone from 'vue2-dropzone'
import dropzoneOptions from '../helper/dropzoneOptions'

export default {
  name: 'ReplyForm',

  data() {
    return {
      spinner: false,
      message: '',
      attachments: [],
      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    }
  },

  props: {
    userEmail: String,
    ticketRef: String
  },

  methods: {
    handleSubmit() {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      let formData = new FormData()

      formData.append('ticketID', this.ticketRef)
      formData.append('message', this.message)
      formData.append('email', this.userEmail)
      formData.append('originURL', window.location.origin)
      formData.append('attachments', JSON.stringify(this.attachments))

      let path = this.$store.state.authToken == '' ? '/ticket/reply' : '/user/ticket/reply'
      axios
        .post(path, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.$v.$reset() //empty form
            this.message = '' // fee back
            this.attachments = []
            this.$refs.myVueDropzone.removeAllFiles()
            //this.ticket.threads.push(res.data.replies);
            this.$emit('newThread', res.data.replies)
            this.$swal('Done!', res.data.message, 'success')
          } else {
            this.$swal('Oops!', res.data.message, 'error')
          }
          this.spinner = false
        })
        .catch(err => {
          this.spinner = false
        })
    },

    uploadFileAdded(file) {
      this.spinner = true // vdropzone-file-added( file)
    },
    uploadError(file) {
      this.spinner = false // vdropzone-file-added( file)
      this.$swal('Oops!', file.fileName + ' not uploaded.', 'error')
    },
    uploadSuccess(file, response) {
      this.spinner = false //vdropzone-success( file, response)
      this.attachments.push({
        name: response.link,
        size: file.size,
        original_name: file.name
      })
    }
  },

  computed: {
    dropzoneOptions() {
      return dropzoneOptions
    }
  },

  validations: {
    message: { required, minLength: minLength(10) }
  },

  components: {
    VueEditor,
    vueDropzone: vue2Dropzone
  }
}
</script>
