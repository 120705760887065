<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3 col-sm-8 offset-md-2">
        <div class="card mb-3">
          <div class="card-header">
            <BackIcon /><b-icon-box-arrow-in-right v-if="!$routerHistory.hasPrevious()"></b-icon-box-arrow-in-right> Login
          </div>
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group mb-3">
                <label for="email">Email</label>
                <input
                  v-model="user.email"
                  type="text"
                  class="form-control"
                  placeholder="Email"
                  :class="{ 'is-invalid': $v.user.email.$error, 'is-valid': !$v.user.email.$invalid }"
                />
                <div class="invalid-feedback">
                  <span v-if="!$v.user.email.required">Email is required.</span>
                </div>
              </div>
              <div class="form-group mb-3">
                <label for="email">Password</label>
                <input
                  v-model="user.password"
                  type="password"
                  class="form-control"
                  placeholder="Password"
                  :class="{ 'is-invalid': $v.user.password.$error, 'is-valid': !$v.user.password.$invalid }"
                />
                <div class="invalid-feedback">
                  <span v-if="!$v.user.password.required">Password is required.</span>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <button v-if="!spinner" type="submit" class="btn btn-warning">Login</button>
                  <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
                </div>
                <div class="col-6 text-right">
                  <router-link :to="{ name: 'Register' }">Sign up</router-link>
                  <br />
                  <router-link to="/password-reset">Forgot Password?</router-link>
                  <br />
                </div>
                <!-- /.col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import BackIcon from "@/components/BackIcon.vue";

export default {
  name: "MyTickets",

  components: { BackIcon },

  data() {
    return {
      spinner: false,
      user: {
        email: "",
        password: "",
      },
    };
  },

  methods: {
    submit() {
      this.spinner = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.spinner = false;
        return;
      }
      let formData = new FormData();
      formData.append("email", this.user.email);
      formData.append("password", this.user.password);
      axios.post("/auth", formData).then((res) => {
        if (res.data.status == "success") {
          this.$store.state.authUser = res.data.user;
          this.$store.state.authToken = res.data.token;
          axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.token;
          window.localStorage.setItem("log_uuid", res.data.log); // this is auth log uuid for server to retrieve JWT token

          // handle all first level roles
          if (this.$store.state.firstLevelRoles.includes(res.data.user.Role)) {
            this.$router.push(this.$store.state.firstLevelRoles[0]);
            return;
          }
          // handle all second level roles
          if (this.$store.state.secondLevelRoles.includes(res.data.user.Role)) {
            this.$router.push(this.$store.state.secondLevelRoles[0]);
            return;
          }
          // handle for other roles
          this.$router.push(res.data.user.Role);
          return;
        } else {
          this.$store.state.authUser = {};
          this.$swal("", res.data.message, "error");
        }
        this.spinner = false;
      });
    },
  },

  validations: {
    user: {
      email: { required },
      password: { required },
    },
  },
};
</script>

<style scoped>
/* input {
  font-size: 1rem;
} */
</style>
