var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-header"},[_vm._v("Knowledge Base")]),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.kbs,"line-numbers":false,"search-options":{
              enabled: true,
              trigger: 'enter'
            },"pagination-options":{
              enabled: false,
              perPage: 50000
            },"sort-options":{
              enabled: true,
              multipleColumns: true
            },"styleClass":"vgt-table condensed myGoodTable"}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }