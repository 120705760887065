<template>
  <div id="EditDepartmentAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Update</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">{{ name }}</div>
          <div class="card-body">
            <div class="col-md-8">
              <!-- <h3>Please provide Supervisor name</h3> -->
              <!-- Create form -->
              <form @submit.prevent="updateDepartment">
                <div class="form-group">
                  <label for="name">
                    Department Name
                    <span class="text-danger">*</span>
                  </label>
                  <input type="text" v-model="name" class="form-control" :class="{ 'is-invalid': $v.name.$error, 'is-valid': !$v.name.$invalid }" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.name.required">Department name is required.</span>
                    <span v-if="!$v.name.minLength">Department name must be at least {{ $v.name.$params.minLength.min }} letters.</span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="name">Department description</label>
                  <textarea v-model="description" class="form-control" rows="3" />
                </div>
                <button class="btn btn-danger btn-block">Update Department</button>
              </form>
              <!-- / Create form -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "EditDepartmentAdmin",

  data() {
    return {
      departments: [],
      name: "",
      description: "",
    };
  },

  mounted() {
    let loading = this.$loading.show();
    axios.get(`/admin/department/${this.$route.params.uuid}`).then((res) => {
      this.name = res.data.department.name;
      this.description = res.data.department.description;
      loading.hide();
    });
  },

  methods: {
    updateDepartment() {
      let loading = this.$loading.show();
      this.$v.$touch();
      if (this.$v.$invalid) {
        loading.hide();
        return;
      }
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("description", this.description);
      formData.append("uuid", this.$route.params.uuid);
      axios.post("admin/department/edit", formData).then((res) => {
        if (res.data.status == "success") {
          //res.data.department.subCategories = [];
          //this.departments.push(res.data.department);
          this.$swal("", `${this.name} uppdated successfully`, "success");
          this.$router.push({ name: "DepartmentsAdmin" });
        } else {
          this.$swal("", res.data.message, "error");
        }
        loading.hide();
      });
    },
  },

  computed: {
    client() {
      return this.$store.state.client;
    },
  },

  created() {
    //this.$store.commit("SET_LAYOUT", "agent-layout");
  },

  validations: {
    name: { required, minLength: minLength(2) },
    //description: { minLength: minLength(2) }
  },
};
</script>
