<template>
  <div id="EditForm">
    <form @submit.prevent="update" class="mt-4">
      <div class="form-group">
        <label for="message">
          Question
          <span class="text-danger">*</span>
        </label>
        <textarea v-model="form.question" class="form-control" rows="2"></textarea>
        <div class="text-danger text-xs" v-show="$v.form.question.$error">
          <span v-if="!$v.form.question.required">Question is required.</span>
        </div>
      </div>
      <div class="form-group">
        <label for="message">
          Answer
          <span class="text-danger">*</span>
        </label>
        <vue-editor v-model="form.answer" :editorToolbar="editorToolbar"></vue-editor>
        <div class="text-danger text-xs" v-show="$v.form.question.$error">
          <span v-if="!$v.form.answer.required">Answer is required.</span>
          <span v-if="!$v.form.answer.minLength">Answer must be at least {{ $v.form.answer.$params.minLength.min }} letters.</span>
        </div>
      </div>
      <button type="submit" class="btn btn-danger btn-lg">Update</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'EditFormKnowledgeBaseAdmin',

  data() {
    return {
      form: {
        question: this.kbObject.question,
        answer: this.kbObject.answer
      },

      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    }
  },

  props: {
    kbIndex: Number,
    kbObject: {}
  },

  methods: {
    update() {
      let loading = this.$loading.show()
      this.$v.$touch()
      if (this.$v.$invalid) {
        loading.hide()
        return
      }
      let formData = new FormData()
      formData.append('question', this.form.question)
      formData.append('answer', this.form.answer)
      formData.append('uuid', this.kbObject.uuid)

      axios.post('/admin/knowledge-base/edit', formData).then(res => {
        if (res.data.status == 'success') {
          this.$v.$reset()
          this.$emit('hideBack', {
            updatedFaqIndex: this.kbIndex,
            updatedFaqObj: res.data.kb
          })
        } else {
          this.$swal('', res.data.message, 'error')
        }
        loading.hide()
      })
    }
  },

  validations: {
    form: {
      question: { required },
      answer: { required, minLength: minLength(10) }
    }
  },

  components: {
    VueEditor
  }
}
</script>
