<template>
  <div>
    <!-- Form -->
    <div class="container" v-show="formShow">
      <div class="row">
        <div class="col-md-6 offset-md-3 col-sm-8 offset-md-2">
          <div class="card mb-3">
            <div class="card-header"><BackIcon /><b-icon-stickies v-if="!$routerHistory.hasPrevious()"></b-icon-stickies> My Tickets</div>
            <div class="card-body">
              <form @submit.prevent="submit">
                <!--  -->
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="text" v-model="email" class="form-control" placeholder="john@gmail.com" :class="{ 'is-invalid': $v.email.$error, 'is-valid': !$v.email.$invalid }" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.email.required">Email is required.</span>
                    <span v-if="!$v.email.email">Email must be valid email address.</span>
                  </div>
                </div>

                <div class="form-group">
                  <label for="email">Phone </label>
                  <input type="text" v-model="phone" class="form-control" placeholder="e.g. 08011223344" :class="{ 'is-invalid': $v.phone.$error, 'is-valid': !$v.phone.$invalid }" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.phone.required">Phone is required.</span>
                    <span v-if="!$v.phone.minLength">Phone must be at least {{ $v.phone.$params.minLength.min }} letters.</span>
                    <span v-if="!$v.phone.maxLength">Phone must be at most {{ $v.phone.$params.maxLength.max }} letters.</span>
                  </div>
                </div>
                <!--  -->
                <button v-if="!spinner" class="btn btn-warning" type="submit"><i class="far fa-paper-plane mr-1"></i> Submit</button>
                <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Ticket List -->
    <div class="container-fluid" v-show="!formShow">
      <div class="row">
        <div class="col-12">
          <div class="card mb-3">
            <div class="card-header"><BackIcon /><b-icon-stickies v-if="!$routerHistory.hasPrevious()"></b-icon-stickies> My Tickets {{ totalTickets | thousandSeparator }}</div>
            <div class="card-body">
              <List :tickets="tickets" :totalTickets="totalTickets" routeName="TicketDetails" @pagination="pagination" ref="listComponent"></List>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import List from '@/components/List.vue'
import BackIcon from '@/components/BackIcon.vue'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'MyTickets',

  data() {
    return {
      formShow: true,
      spinner: false,
      email: '',
      phone: '',

      tickets: [],
      status: '',
      totalTickets: 0,
      params: {}
    }
  },

  methods: {
    submit() {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      this.loadTickets()
    },

    loadTickets() {
      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('phone', this.phone)

      axios
        .post('/tickets', formData, {
          params: this.params
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.formShow = false
            this.tickets = res.data.tickets
            this.totalTickets = res.data.totalTickets
          } else {
            this.$swal('Oops!', res.data.message, 'error')
          }
          this.spinner = false
        })
    },

    pagination(data) {
      this.params = data
      this.loadTickets()
    }
  },

  components: {
    List,
    BackIcon
  },

  validations: {
    email: { required, email },
    phone: { required, minLength: minLength(11), maxLength: maxLength(11) }
  }
}
</script>

<style></style>
