<template>
  <div id="MyTicketsAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">My Ticket</div>
      </div>
    </div>

    <div class="page-header-subtitle" v-if="status != 'all'">{{ status }}</div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">
            {{ status | titleCase }}
          </div>
          <div class="card-body">
            <List :tickets="tickets" routeName="TicketDetailsAdmin"></List>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import List from "@/components/List.vue";

export default {
  name: "MyTicketsAdmin",

  data() {
    return {
      status: "",
      tickets: [],
      totalAvailable1: 0,
      //lastTicketUUID: "",
      itemsPerLoad: 2000,
    };
  },

  watch: {
    "$route.params.status": function (status) {
      this.tickets = [];
      this.itemsPerLoad = 2000;
      this.status = status;
      this.loadTickets();
      window.scrollTo(0, 0);
    },
  },

  mounted() {
    let status = "all";
    if (this.$route.params.status != undefined) {
      status = this.$route.params.status;
    }
    this.status = status;
    this.loadTickets();
  },

  methods: {
    loadTickets() {
      let loading = this.$loading.show();
      axios
        .get("/user/tickets", {
          params: {
            status: this.status,
            itemsPerLoad: this.itemsPerLoad,
            //lastTicketUUID: this.lastTicketUUID,
          },
        })
        .then((res) => {
          /* let arr = res.data.tickets;
          for (let index = 0; index < arr.length; index++) {
            this.tickets.push(arr[index]);
          }
          this.totalAvailable1 = res.data.totalAvailable; */
          if (res.data.status == "success") {
            this.tickets = res.data.tickets;
          }
          loading.hide();
        });
    },
  },

  components: {
    List,
  },
};
</script>
<style>
</style>

