import TicketDetailsSecondLevel from '../views/second-level-support/TicketDetailsSecondLevel.vue'
import TicketsSecondLevel from '../views/second-level-support/TicketsSecondLevel.vue'
import ChangePasswordSecondLevel from '../views/ChangePassword.vue'
import UserDetailsSecondLevel from '../views/UserDetails.vue'
import SecondLevelLayout from '../layouts/SecondLevelSupport.vue'
import ProfileSecondLevel from '../views/Profile.vue'
import auth from '../helper/auth'

const secondLevelRoutes = [
  {
    path: '/second-level-support',
    component: SecondLevelLayout,
    beforeEnter: auth.middlewareSecondLevel,
    children: [
      {
        path: '/',
        name: 'HomeSecondLevel',
        component: TicketsSecondLevel
      },
      {
        path: 'profile',
        name: 'ProfileSecondLevel',
        component: ProfileSecondLevel
      },
      {
        path: 'tickets/:status', // status is sticket state e.g. open, closed, escalated
        name: 'TicketsSecondLevel',
        component: TicketsSecondLevel
      },
      {
        path: 'ticket/:ref',
        name: 'TicketDetailsSecondLevel',
        component: TicketDetailsSecondLevel
      },

      {
        path: 'change-password',
        name: 'ChangePasswordSecondLevel',
        component: ChangePasswordSecondLevel
      },

      {
        path: 'user/:uuid',
        name: 'UserDetailsSecondLevel',
        component: UserDetailsSecondLevel
      },

      {
        path: 'SLA-Breached',
        name: 'SLABreached_SL',
        component: () => import('../views/reports/SLABreached.vue')
      },
      {
        path: 'SLA-Compliance',
        name: 'SLACompliance_SL',
        component: () => import('../views/reports/SLACompliance.vue')
      },
      {
        path: 'changes-history',
        name: 'ChangesHistory_SL',
        component: () => import('../views/reports/ChangesHistory.vue')
      },
      {
        path: 'Top-10-Cases',
        name: 'Top10Cases_SL',
        component: () => import('../views/reports/Top10Cases.vue')
      }
    ]
  }
]

export default secondLevelRoutes
