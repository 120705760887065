<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- <TicketNextPrev routeName="TicketDetailsCustomer" :status="ticket.status.name" /> -->
        <div class="card">
          <div class="card-header">
            <router-link :to="{ name: 'HomeCustomer' }" style="color: #fff">
              <b-icon-arrow-left></b-icon-arrow-left>
            </router-link>
            <!-- <BackIcon /> <b-icon-sticky v-if="!$routerHistory.hasPrevious()"></b-icon-sticky> -->
            Ticket #: {{ this.$route.params.ref }}
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <table class="table table-sm">
                  <tbody>
                    <tr>
                      <th>Status:</th>
                      <td>
                        <TicketStatusBadge :status="ticket.status.name" />
                      </td>
                    </tr>
                    <tr>
                      <th>Category:</th>
                      <td>{{ ticket.category.name }}</td>
                    </tr>
                    <tr>
                      <th>Subcategory:</th>
                      <td>{{ ticket.subCategory.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <table class="table table-sm">
                  <tbody>
                    <tr>
                      <th>Source:</th>
                      <td>{{ ticket.source | titleCase }}</td>
                    </tr>
                    <tr>
                      <th>Priority:</th>
                      <td>
                        {{ ticket.priority | titleCase }}
                      </td>
                    </tr>
                    <tr>
                      <th>Created At:</th>
                      <td>{{ ticket.updatedAt | formatDateTime }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Status buttons -->
            <div class="toolbuttons">
              <!-- Reopen -->
              <ReopenModal :ticket="ticket" @newStatus="newStatus" path="customer" />
              <!-- Reopen -->
              <ReviewModal :ticket="ticket" />
              <ReviewsAllModal :ticket="ticket" />
            </div>
          </div>
        </div>
        <!-- Timeline -->
        <div class="card">
          <!-- <div class="card-header">ddd</div> -->
          <div class="card-body">
            <ul class="timeline">
              <li>
                <i class="fa fa-envelope bg-dark"><b-icon-envelope></b-icon-envelope></i>
                <div class="timeline-item">
                  <span class="time">
                    <b-icon-clock></b-icon-clock>
                    {{ ticket.updatedAt | formatDateTime }}
                  </span>
                  <h3 class="timeline-header">
                    <b>{{ ticket.user.name }}'s</b>
                    message
                  </h3>
                  <div class="timeline-body">
                    <h4>{{ ticket.title }}</h4>
                    <iframe v-if="ticket.source == 'email'" :srcdoc="ticket.body" width="100%" height="500"></iframe>
                    <div v-else v-html="ticket.body"></div>
                  </div>
                  <div class="timeline-footer" v-if="ticket.attachments.length > 0">
                    <span>Attachment(s)</span>
                    <br />
                    <div v-for="(attachment, index) in ticket.attachments" :key="attachment.uuid">
                      <a :href="attachment.name" class="text-danger" target="_blank">
                        <b-icon-paperclip></b-icon-paperclip>
                        <span v-if="attachment.original_name !== ''">{{ attachment.original_name }}</span>
                        <span v-else>Download attachment {{ index + 1 }}</span>
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              </li>

              <!-- THREAD  -->
              <li v-for="thread in ticket.threads" :key="thread.uuid">
                <i v-if="thread.user.email == ticket.user.email" class="fa fa-share bg-danger"><b-icon-arrow90deg-left></b-icon-arrow90deg-left></i>
                <i v-if="thread.user.email != ticket.user.email" class="fa fa-reply bg-primary"><b-icon-arrow90deg-right></b-icon-arrow90deg-right></i>

                <div class="timeline-item">
                  <span class="time">
                    <b-icon-clock></b-icon-clock>
                    {{ thread.updatedAt | formatDateTime }}
                  </span>
                  <h3 class="timeline-header">
                    <span v-if="thread.user.email == ticket.user.email">
                      <b>{{ thread.user.name }}'s </b>
                      response
                    </span>
                    <span v-else-if="thread.user.email != ticket.user.email">
                      <span class="text-success">{{ thread.user.name }}'s</span> response
                    </span>
                  </h3>
                  <div class="timeline-body">
                    <iframe v-if="ticket.source == 'email'" :srcdoc="thread.body" width="100%" height="250"></iframe>
                    <div v-else v-html="thread.body"></div>
                  </div>
                  <div class="timeline-footer" v-if="thread.attachments.length > 0">
                    <span>Attachment(s):</span>
                    <br />
                    <div v-for="(attachment, index) in thread.attachments" :key="attachment.uuid">
                      <a :href="attachment.name" class="text-danger" target="_blank">
                        <b-icon-paperclip></b-icon-paperclip>
                        <span v-if="attachment.original_name !== ''">{{ attachment.original_name }}</span>
                        <span v-else>Download attachment {{ index + 1 }}</span>
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              </li>

              <!-- REPLY FORM -->
              <li>
                <i class="far fa-paper-plane bg-dark"></i>
                <div class="timeline-item">
                  <h3 class="timeline-header">Reply</h3>
                  <div class="timeline-body">
                    <!-- replay form  -->
                    <ReplyForm :ticketRef="ticket.ref" :userEmail="authUser.Email" @newThread="newThread"></ReplyForm>
                    <!-- replay form  -->
                  </div>
                </div>
              </li>

              <li>
                <i class="fa fa-clock bg-dark"><b-icon-clock></b-icon-clock></i>
              </li>
            </ul>
          </div>
        </div>
        <!-- End of timeline -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'
//import CommentForm from "../../components/CommentForm";
import { required } from 'vuelidate/lib/validators'
import ReopenModal from '@/components/ReopenModal.vue'
//import TreatedModal from '@/components/TreatedModal.vue'
//import ResolveModal from '@/components/ResolveModal.vue'
//import ChangeLogModal from '@/components/ChangeLogModal.vue'
import TicketStatusBadge from '@/components/TicketStatusBadge.vue'
//import CountDown from '@/components/CountDown.vue'
import TicketNextPrev from '@/components/TicketNextPrev.vue'
//import AssignIndvModal from '@/components/AssignIndvModal.vue'
import ReplyForm from '../../components/ReplyForm'
import ReviewModal from '../../components/ReviewModal'
import ReviewsAllModal from '../../components/ReviewsAllModal'

export default {
  name: 'TicketDetailsCustomer',

  data() {
    return {
      ticket: {
        updatedAt: '',
        status: { name: '', slug: '' },
        category: { name: '' },
        subCategory: { name: '' },
        user: { name: '' },
        attachments: [],
        threads: []
      },
      departments: [],
      assignDepartmentUUID: '',
      //assignTitle: "Assign",
      spinner: false
    }
  },

  watch: {
    '$route.params.ref': function(ref) {
      this.loadTicket(ref)
      window.scrollTo(0, 0)
    }
  },

  mounted() {
    this.loadTicket(this.$route.params.ref)
    window.scrollTo(0, 0)
  },

  methods: {
    loadTicket(ref) {
      this.spinner = true
      axios
        .get('/customer/ticket/' + ref)
        .then(res => {
          if (res.data.status == 'success') {
            this.ticket = res.data.ticket
            this.departments = res.data.departments
            //res.data.ticket.status.slug === "assigned" ? (this.assignTitle = "Re-Assign") : (this.assignTitle = "Assign");
          } else {
            this.$swal('Ooops!', res.data.message, 'error')
            this.$router.push({ name: 'Home' })
          }
          this.spinner = false
        })
        .catch(error => {})
    },

    newThread(e) {
      this.ticket.threads.push(e)
    },

    newStatus(data) {
      this.ticket.status = data.newStatus
      this.ticket.statusLog.push(data.newStatusLog)
    },

    customEscalateSuccess(newStatus) {
      this.ticket.status = newStatus
    },
    priorityChangeSuccess(priority) {
      this.ticket.priority = priority
    },
    categoryChangeSuccess(cat) {
      this.ticket.category.name = cat.categoryName
      this.ticket.subCategory.name = cat.subcategoryName
    }
  },

  computed: {
    topCategoryLabel() {
      return this.$store.state.client.topCategoryLabel
    },
    subCategoryLabel() {
      return this.$store.state.client.subCategoryLabel
    },
    authUser() {
      return this.$store.state.authUser
    },
    assignTitle() {
      let t = 'Assign'
      if (this.ticket.status.slug == 'assigned') {
        t = 'Re-Assign'
      }
      return t
    }
  },

  components: {
    //CommentForm,
    BackIcon,
    TicketStatusBadge,
    //CommentsModal,
    //AssignModal,
    ReopenModal,
    //ResolveModal,
    //ChangeLogModal,
    // CountDown,
    // TreatedModal,
    TicketNextPrev,
    //AssignIndvModal
    ReplyForm,
    ReviewModal,
    ReviewsAllModal
  },

  validations: {
    assignDepartmentUUID: { required }
  }
}
</script>

<style scoped>
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
</style>
