<template>
  <span id="CommentsModal" style="display: inline">
    <a href="#" v-b-modal="modalID" title="Comments" class="btn btn-outline-info btn-sm m-2">Comments</a>

    <!-- arrow-right -->
    <b-modal :id="modalID" :title="`#${ticket.ref} comments`" size="xl" no-close-on-backdrop @shown="modalShown">
      <div id="comments">
        <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
        <div v-if="!spinner" class="list-group list-group-flush">
          <div class="list-group-item list-group-item-action" v-for="(c, i) in comments" :key="i">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                <b>{{ c.user.name | shortenStr(50) }}</b>
              </h5>
              <small class="text-muted">{{ c.updatedAt | formatDateTime }}</small>
            </div>

            <div class="mb-1 cont" v-html="c.body"></div>
            <div style="font-size:0.8rem" v-if="c.attachments.length > 0">
              <span>Attachment(s)</span>
              <br />
              <div v-for="(attachment, index) in c.attachments" :key="attachment.uuid">
                <a :href="attachment.name" class="text-danger" target="_blank">
                  <b-icon-paperclip></b-icon-paperclip>
                  <span v-if="attachment.original_name !== ''">{{ attachment.original_name }}</span>
                  <span v-else>Download attachment {{ index + 1 }}</span>
                </a>
                <br />
              </div>
            </div>

            <!-- <small class="text-muted">{{ c.updatedAt | formatDateTime }}</small> -->
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <CommentForm :userEmail="authUser.Email" :ticketRef="ticket.ref" @newComment="newComment" />
        </div>
      </template>
    </b-modal>
  </span>
</template>

<script>
import axios from 'axios'
import CommentForm from '@/components/CommentForm.vue'

export default {
  name: 'CommentsModal',

  data() {
    return {
      spinner: false,
      modalID: 'CommentsModal',
      priority: '',
      comments: []
    }
  },

  /* updated() {
    this.comments = this.ticket.comments;
  }, */

  props: {
    ticket: { type: Object }
  },

  methods: {
    newComment(data) {
      //this.comments.push(data);
      this.comments = [data, ...this.comments]
    },

    modalShown(e) {
      this.spinner = true
      axios.get(`/comments?ticketID=${this.ticket.ref}`).then(res => {
        if (res.data.status == 'success') {
          this.comments = res.data.comments
        } else {
          this.$swal('Ooops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  },

  computed: {
    /* client() {
      return this.$store.state.client;
    }, */
    authUser() {
      return this.$store.state.authUser
    }
  },
  components: { CommentForm }
}
</script>

<style scoped>
#comments {
  width: 100%;
  height: 300px;
  overflow-y: auto;
}
h5 {
  font-size: 0.95rem;
}
.cont {
  font-size: 0.9rem;
}
.text-muted {
  font-size: 0.75rem;
}
</style>
