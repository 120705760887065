<template>
  <span>
    <span v-if="status == 'Open'" class="badge badge-danger">{{ status }}</span>
    <span v-else-if="status == 'Assigned'" class="badge badge-primary">{{ status }}</span>
    <span v-else-if="status == 'Resolved'" class="badge badge-success">{{ status }}</span>
    <span v-else-if="status == 'Reopened'" class="badge badge-danger">{{ status }}</span>
    <span v-else-if="status == 'Treated'" class="badge badge-secondary">{{ status }}</span>
    <span v-else-if="status == 'Breached'" class="badge badge-dark">{{ status }}</span>
    <span v-else class="badge badge-warning">{{ status }}</span>
  </span>
</template>

<script>
export default {
  name: "TicketStatusBadge",
  props: {
    status: String,
  },
};
</script>

<style></style>
