<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-sm-12 offset-sm-12">
        <div class="card mb-3">
          <div class="card-header"><BackIcon /><b-icon-person v-if="!$routerHistory.hasPrevious()"></b-icon-person> User Profile</div>
          <div class="card-body">
            <table class="table table-striped ">
              <tr>
                <th>Name</th>
                <td>{{ user.name }}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{ user.email }}</td>
              </tr>
              <tr>
                <th>Role</th>
                <td>{{ user.role.name }}</td>
              </tr>
              <tr v-if="user.role.slug == 'user'">
                <th>Email</th>
                <td>{{ user.email }}</td>
              </tr>
              <tr v-if="user.role.slug == 'second-level-support'">
                <th>Department(s)</th>
                <td>
                  <ul v-for="(cat, i) in user.departments" :key="i">
                    <li>{{ cat.name }}</li>
                  </ul>
                </td>
              </tr>
            </table>

            <p>
              <!-- <router-link :to="'/' + authUser.Role + '/change-password'">Change Password</router-link> -->
              <router-link :to="changePassword">Change Password</router-link>
            </p>
            <p>
              <Logout kind="link" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BackIcon from "@/components/BackIcon.vue";
import Logout from "@/components/Logout.vue";

export default {
  data() {
    return {
      user: { role: { name: "" } },
    };
  },

  created() {
    axios.get("/auth/user-profile").then((res) => {
      if (res.data.status == "success") {
        this.user = res.data.user;
      } else {
        this.$swal("Ooops!", res.data.message, "error");
      }
    });
  },

  computed: {
    authUser() {
      return this.$store.state.authUser;
    },

    changePassword() {
      let role = this.$store.state.authUser.Role;

      if (role == "first-level-support-supervisor-1" || role == "first-level-support-supervisor-2") {
        role = "first-level-support";
      } else if (role == "second-level-support-supervisor-1" || role == "second-level-support-supervisor-2") {
        role = "second-level-support";
      }

      return `/${role}/change-password`;
    },
  },
  components: { BackIcon, Logout },
};
</script>

<style></style>
