import Vue from 'vue'
import VueRouter from 'vue-router'
import VueRouterBackButton from 'vue-router-back-button'

import userRoutes from './user'
import customerRoutes from './customer'
import firstLevelSupportRoutes from './first-level-support'
import publicRoutes from './public'
import adminRoutes from './admin'
import secondLevelSupportRoutes from './second-level-support'

Vue.use(VueRouter)

const allRoutes = []

const routes = allRoutes.concat(firstLevelSupportRoutes, secondLevelSupportRoutes, customerRoutes, userRoutes, adminRoutes, publicRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.use(VueRouterBackButton, {
  router
})

export default router
