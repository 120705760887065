<template>
  <vue-good-table
    mode="remote"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-per-page-change="onPerPageChange"
    @on-search="onSearch"
    :totalRows="totalTickets"
    :columns="columns"
    :rows="tickets"
    :line-numbers="true"
    :search-options="{
      enabled: true,
      trigger: 'enter',
      placeholder: 'Search this list...'
    }"
    :pagination-options="{
      mode: 'pages',
      enabled: true,
      perPage: 100,
      perPageDropdown: [25, 75, 100],
      setCurrentPage: serverParams.page
    }"
    :sort-options="{
      enabled: true,
      multipleColumns: false
    }"
    styleClass="vgt-table condensed myGoodTable"
  >
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'ref'">
        <router-link :to="{ name: routeName, params: { ref: props.row.ref } }">
          <b>{{ props.row.ref }}</b>
        </router-link>
      </span>
      <span v-if="props.column.field == 'status'">
        <TicketStatusBadge :status="props.row.status" />
      </span>
      <span v-if="props.column.field == 'title'"> {{ props.row.title }} </span>
      <span v-if="props.column.field == 'category'"> {{ props.row.category }} </span>
      <span v-if="props.column.field == 'subcategory'">{{ props.row.subcategory }}</span>
      <span v-if="props.column.field == 'createdBy'">{{ props.row.createdBy }}</span>
      <span v-if="props.column.field == 'createdByEmail'">{{ props.row.createdByEmail }}</span>
      <span v-if="props.column.field == 'priority'">{{ props.row.priority }}</span>
      <span v-if="props.column.field == 'source'">{{ props.row.source }}</span>
      <span v-if="props.column.field == 'createdAt'">{{ props.row.createdAt | formatDateTime }}</span>
      <span v-if="props.column.field == 'updatedAt'">
        <span v-if="props.row.status == 'Resolved'"> {{ props.row.updatedAt | formatDateTime }}</span>
        <span v-else>N/A</span>
      </span>
    </template>
  </vue-good-table>
</template>

<script>
import TicketStatusBadge from '@/components/TicketStatusBadge.vue'

export default {
  components: {
    TicketStatusBadge
  },

  props: {
    routeName: String,
    tickets: { type: Array },
    totalTickets: { type: Number, default: 0 }
  },

  watch: {
    tickets: function() {
      this.ticketsBK = this.tickets
    }
  },

  data() {
    return {
      ticketsBK: [],
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 100
      },
      firstID: 0,
      lastID: 0,
      myparam: {}
    }
  },

  methods: {
    async loadItems() {
      if (this.lastID === 0) {
        this.firstID = this.tickets[0].id
        this.lastID = this.tickets[this.tickets.length - 1].id
      }

      this.myparam = { ...this.myparam, goodTableParams: JSON.stringify(this.serverParams), firstID: this.firstID, lastID: this.lastID }
      /* let searchType = this.$route.query.searchType
      let searchTerm = this.$route.query.searchTerm
      if (searchType !== '' && searchType !== undefined) {
        this.path = 'search-tickets'
        this.myparam = { ...this.myparam, searchType: searchTerm, searchTerm: searchTerm }
      } */
      this.isLoading = true
      await this.$emit('pagination', this.myparam)
      this.isLoading = false
    },

    searchTickets(data) {
      this.myparam = { ...this.myparam, searchType: data.searchType, searchTerm: data.searchTerm, ticketStatusID: data.ticketStatusID, categoryID: data.categoryID, subcategoryID: data.subcategoryID, studycenterID: data.studycenterID, facultyID: data.facultyID, programmeID: data.programmeID, startDate: data.startDate, endDate: data.endDate }
      this.path = 'search-tickets'
      this.loadItems()
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      if (params[0].type === 'desc') {
        this.tickets.reverse()
        return
      }

      const field = params[0].field
      this.tickets.sort((a, b) => {
        if (a[field] < b[field]) {
          return -1
        }
        if (a[field] > b[field]) {
          return 1
        }
        return 0
      })
    },

    onSearch(params) {
      let tickets = []
      let searchTerm = params.searchTerm

      tickets = this.ticketsBK.filter(el => {
        return el.ref.includes(searchTerm) || el.status.includes(searchTerm) || el.title.includes(searchTerm) || el.category.includes(searchTerm) || el.subcategory.includes(searchTerm) || el.createdBy.includes(searchTerm) || el.createdByEmail.includes(searchTerm) || el.priority.includes(searchTerm) || el.source.includes(searchTerm) || el.createdAt.includes(searchTerm) || el.updatedAt.includes(searchTerm)
      })

      this.tickets = tickets
    },

    cleanUpPagniationData() {
      this.firstID = 0
      this.lastID = 0
      this.myparam = {}
      this.serverParams.page = 1
    }
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'ReferenceID',
          field: 'ref'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Subject',
          field: 'title'
        },
        {
          label: 'Category',
          field: 'category'
        },
        {
          label: 'Subcategory',
          field: 'subcategory'
        },

        {
          label: 'CreatedBy',
          field: 'createdBy'
        },
        {
          label: 'Email',
          field: 'createdByEmail'
        },
        /*  {
          label: "AssignedTo",
          field: "assignedTo",
        }, */
        {
          label: 'Channel',
          field: 'source'
        },
        {
          label: 'Priority',
          field: 'priority'
        },
        {
          label: 'CreatedAt',
          field: 'createdAt'
        },
        {
          label: 'ResolvedAt',
          field: 'updatedAt'
        }
      ]
      return cols
    }
  }
}
</script>

<style>
.myGoodTable {
  font-size: 0.8rem !important;
}
</style>
