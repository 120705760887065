<template>
  <div id="PriorityModal" style="display: inline">
    <a href="#" v-b-modal="modalID" title="Change Priority">Priority</a>

    <!-- arrow-right -->
    <b-modal :id="modalID" title="Change Priority" size="sm" hide-footer no-close-on-backdrop>
      <form @submit.prevent="submit">
        <!-- priority -->
        <div class="form-group">
          <label for="subCategory">Priority</label>
          <select class="form-control" v-model="priority" :class="{ 'is-invalid': $v.priority.$error, 'is-valid': !$v.priority.$invalid }">
            <option placeholder="Select..." selected>Select...</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
            <option value="urgent">Urgent</option>
          </select>
          <div class="invalid-feedback">
            <span v-if="!$v.priority.required">Priority is required.</span>
          </div>
        </div>

        <button v-if="!spinner" class="btn btn-warning" type="submit"><i class="far fa-paper-plane mr-1"></i> Submit</button>
        <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "PriorityModal",

  data() {
    return {
      spinner: false,
      modalID: "PriorityModal",
      priority: "",
    };
  },

  props: {
    ticket: { type: Object },
  },

  methods: {
    submit() {
      this.spinner = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.spinner = false;
        return;
      }

      let formData = new FormData();
      formData.append("priority", this.priority);
      formData.append("ref", this.ticket.ref);

      axios.post("/first-level/change-priority", formData).then((res) => {
        if (res.data.status == "success") {
          this.$swal("Priority", res.data.message, "success");
          this.$emit("priorityChangeSuccess", res.data.newPriority);
          this.$bvModal.hide(this.modalID);
        } else {
          this.$swal("Ooops!", res.data.message, "error");
        }
        this.spinner = false;
      });
    },
  },

  computed: {
    client() {
      return this.$store.state.client;
    },
  },

  validations: {
    priority: { required },
  },
};
</script>

<style scoped>
.modal-body {
}
.col {
  height: 450px;
  overflow: auto;
  background-color: #eee;
}
.secondCol {
  border-left: 3px solid #ccc;
  border-right: 3px solid #ccc;
}
.list-group {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.escalateButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}
.list-group-item {
  font-size: 0.8rem;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4eadea;
  border-color: #4eadea;
}
</style>
