<template>
  <span>
    <button v-if="ticket.status.slug !== 'open'" class="btn btn-sm btn-outline-secondary m-2" v-b-modal="modalID">Reviews</button>

    <!-- Modal -->
    <b-modal :id="modalID" :ref="modalID" :title="'Reviews ' + '(' + reviews.length + ')'" size="lg" hide-footer no-close-on-backdrop @shown="loadData">
      <div id="comments">
        <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
        <table v-if="!spinner" class="table table-striped table-sm" style="font-size:.8rem">
          <thead>
            <tr>
              <th>Date/Time</th>
              <th>User</th>
              <th>Ratings</th>
              <th>Review</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="review in reviews" :key="review.uuid">
              <td>
                {{ review.createdAt | formatDateTime }}
              </td>
              <td>
                {{ review.user.name }} <br />
                <small>{{ review.user.email }}</small>
              </td>
              <td>
                <star-rating :rating="review.rate" :star-size="15" :show-rating="false" :read-only="true"></star-rating>
              </td>
              <td>
                {{ review.body }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </span>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ReviewsAllModal',

  props: {
    ticket: Object
  },

  data() {
    return {
      modalID: 'ReviewsAllModal',
      spinner: false,
      reviews: []
    }
  },

  methods: {
    loadData() {
      this.spinner = true
      axios.get('/reviews/' + this.$route.params.ref).then(res => {
        if (res.data.status == 'success') {
          this.reviews = res.data.reviews
          this.spinner = false
        }
      })
    }
  }
}
</script>

<style scoped>
#comments {
  width: 100%;
  height: 400px;
  overflow-y: auto;
}
</style>
