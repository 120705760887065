var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid",attrs:{"id":"AssignDepartmentAdmin"}},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header"},[_vm._v(_vm._s(_vm.department.name))]),_c('div',{staticClass:"card-body"},[_c('form',{staticClass:"form-inline mb-5",on:{"submit":function($event){$event.preventDefault();return _vm.assignUser.apply(null, arguments)}}},[_vm._m(1),_c('div',{staticClass:"form-group mx-sm-3 mb-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedUserUUID),expression:"selectedUserUUID"}],staticClass:"form-control",staticStyle:{"width":"20rem","max-width":"20rem"},attrs:{"id":"exampleFormControlSelect1"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedUserUUID=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',[_vm._v("Select a user...")]),_vm._l((_vm.unAssignedUsers),function(u,i){return _c('option',{key:i,domProps:{"value":u.uuid}},[_vm._v(_vm._s(u.name)+" - "+_vm._s(u.email))])})],2)]),_c('button',{staticClass:"btn btn-primary mb-2",attrs:{"type":"submit"}},[_vm._v("Assign")])]),_c('b',[_vm._v("Assigned Users")]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.assignedUsers,"line-numbers":true,"search-options":{
              enabled: true,
              trigger: 'enter',
            },"pagination-options":{
              enabled: true,
              perPage: 50,
            },"sort-options":{
              enabled: true,
              initialSortBy: [{ field: 'name', type: 'asc' }],
            },"styleClass":"vgt-table condensed myGoodTable"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_vm._v(_vm._s(props.row.name))]):_vm._e(),(props.column.field == 'email')?_c('span',[_vm._v(_vm._s(props.row.email))]):_vm._e(),(props.column.field == 'unassign')?_c('span',[_c('button',{staticClass:"btn btn-xs btn-outline-secondary",attrs:{"title":"unassign"},on:{"click":function($event){return _vm.unassignUser(props.row.uuid, props.row.originalIndex)}}},[_vm._v(" unassign ")])]):_vm._e()]}}])})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"pageTitle"},[_vm._v("Assign")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group mb-2"},[_c('input',{staticClass:"form-control-plaintext",attrs:{"type":"text","readonly":"","id":"staticEmail2","value":"Assign new user"}})])}]

export { render, staticRenderFns }