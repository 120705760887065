var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalTickets,"columns":_vm.columns,"rows":_vm.tickets,"line-numbers":true,"search-options":{
    enabled: true,
    trigger: 'enter',
    placeholder: 'Search this list...'
  },"pagination-options":{
    mode: 'pages',
    enabled: true,
    perPage: 100,
    perPageDropdown: [25, 75, 100],
    setCurrentPage: _vm.serverParams.page
  },"sort-options":{
    enabled: true,
    multipleColumns: false
  },"styleClass":"vgt-table condensed myGoodTable"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.onSearch},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'ref')?_c('span',[_c('router-link',{attrs:{"to":{ name: _vm.routeName, params: { ref: props.row.ref } }}},[_c('b',[_vm._v(_vm._s(props.row.ref))])])],1):_vm._e(),(props.column.field == 'status')?_c('span',[_c('TicketStatusBadge',{attrs:{"status":props.row.status}})],1):_vm._e(),(props.column.field == 'title')?_c('span',[_vm._v(" "+_vm._s(props.row.title)+" ")]):_vm._e(),(props.column.field == 'category')?_c('span',[_vm._v(" "+_vm._s(props.row.category)+" ")]):_vm._e(),(props.column.field == 'subcategory')?_c('span',[_vm._v(_vm._s(props.row.subcategory))]):_vm._e(),(props.column.field == 'createdBy')?_c('span',[_vm._v(_vm._s(props.row.createdBy))]):_vm._e(),(props.column.field == 'createdByEmail')?_c('span',[_vm._v(_vm._s(props.row.createdByEmail))]):_vm._e(),(props.column.field == 'priority')?_c('span',[_vm._v(_vm._s(props.row.priority))]):_vm._e(),(props.column.field == 'source')?_c('span',[_vm._v(_vm._s(props.row.source))]):_vm._e(),(props.column.field == 'createdAt')?_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(props.row.createdAt)))]):_vm._e(),(props.column.field == 'updatedAt')?_c('span',[(props.row.status == 'Resolved')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(props.row.updatedAt)))]):_c('span',[_vm._v("N/A")])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }