<template>
  <span>
    <!-- <button v-if="ticket.status.slug == 'resolved'" class="btn btn-sm btn-success m-2" v-b-modal="modalID">Resolve</button> -->
    <star-rating @rating-selected="setRating" :rating="ratedStars" :star-size="20" :show-rating="false" v-if="ticket.status.slug == 'resolved'"></star-rating>
    <!-- Modal -->
    <b-modal :id="modalID" :ref="modalID" :title="`Review ticket #${ticket.ref}`" size="md" hide-footer no-close-on-backdrop>
      <p>
        <star-rating @rating-selected="setRating" :rating="ratedStars" :star-size="25" :show-rating="false"></star-rating>
      </p>
      <form @submit.prevent="assign">
        <!-- Review -->
        <div class="form-group">
          <label for="review">Add a review</label>
          <textarea class="form-control" v-model="review" :class="{ 'is-invalid': $v.review.$error, 'is-valid': !$v.review.$invalid }" />
          <div class="invalid-feedback">
            <span v-if="!$v.review.required">Review required.</span>
          </div>
        </div>

        <button v-if="!spinner" class="btn btn-success" type="submit">Submit</button>
        <b-spinner v-if="spinner" variant="success" label="Spinning"></b-spinner>
      </form>
    </b-modal>
  </span>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ReviewModal',

  props: {
    ticket: Object
  },

  data() {
    return {
      modalID: 'reviewModal',
      review: '',
      spinner: false,
      ratedStars: 0,
      reviewObj: {}
    }
  },

  validations: {
    review: { required }
  },

  mounted() {
    axios.get('/review/' + this.$route.params.ref).then(res => {
      if (res.data.status == 'success') {
        this.reviewObj = res.data.review
        this.ratedStars = res.data.review.rate
      }
    })
  },

  methods: {
    setRating(rating) {
      this.ratedStars = rating
      this.$bvModal.show(this.modalID)
    },

    assign() {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      let formData = new FormData()
      formData.append('ref', this.ticket.ref)
      formData.append('rating', this.ratedStars)
      formData.append('review', this.review)
      axios.post('/review', formData).then(res => {
        if (res.data.status == 'success') {
          this.$bvModal.hide(this.modalID)
          this.$swal('', res.data.message, 'success')
        } else {
          this.$swal('Oops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  }
}
</script>

<style></style>
