<template>
  <div class="container-fluid" id="TicketsAdmin">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Tickets</div>
        <!-- <div class="subPageTitle" v-if="status != 'all'">{{ status }}</div> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">{{ status | titleCase }} ({{ totalTickets | thousandSeparator }})</div>
          <div class="card-body">
            <List :tickets="tickets" :totalTickets="totalTickets" @pagination="pagination" routeName="TicketDetailsAdmin" ref="listComponent"></List>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import List from '@/components/List.vue'

export default {
  name: 'TicketsAdmin',

  data() {
    return {
      status: '',
      tickets: [],
      totalTickets: 0,
      //lastTicketUUID: "",
      // itemsPerLoad: 2000,
      params: {}
    }
  },

  watch: {
    '$route.params.status': function(status) {
      this.tickets = []
      //this.itemsPerLoad = 2000
      this.status = status
      this.loadTickets()
      this.$refs.listComponent.cleanUpPagniationData() // reset pagination to page 1
      this.params = {} // reset params
      window.scrollTo(0, 0)
    }
  },

  mounted() {
    let status = 'all'
    if (this.$route.params.status != undefined) {
      status = this.$route.params.status
    }
    this.status = status
    this.loadTickets()
  },

  methods: {
    loadTickets() {
      let loading = this.$loading.show()
      axios
        .get('/admin/tickets', {
          params: {
            ...this.params,
            status: this.status
            //itemsPerLoad: this.itemsPerLoad
            //lastTicketUUID: this.lastTicketUUID,
          }
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.tickets = res.data.tickets
            this.totalTickets = res.data.totalTickets
          }
          loading.hide()
        })
    },

    pagination(data) {
      this.params = data
      this.loadTickets()
    }
  },

  components: {
    List
  }
}
</script>
<style></style>
