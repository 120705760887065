import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authUser: {},
    authToken: "",
    firstLevelRoles: ["first-level-support", "first-level-support-supervisor-1", "first-level-support-supervisor-2"],
    secondLevelRoles: ["second-level-support", "second-level-support-supervisor-1", "second-level-support-supervisor-2"],
    //days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    //daysShort: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    //monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    pageTicketList: [],
  },
  mutations: {},
  actions: {},
  modules: {},
});
