<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="searchFilter">
          <!-- <FilterDomain @searchTickets="searchTickets" :clients="clients" /> -->
          <SearchForm @searchTickets="searchTickets" userRole="second-level" ref="searchForm" :paginationParams="params" @paginationReset="paginationReset" />
        </div>

        <div class="card mb-3">
          <div class="card-header"><BackIcon /> <b-icon-stickies v-if="!$routerHistory.hasPrevious()"></b-icon-stickies> {{ label }} {{ totalTickets | thousandSeparator }}</div>
          <div class="card-body">
            <List :tickets="tickets" :totalTickets="totalTickets" routeName="TicketDetailsSecondLevel" @pagination="pagination" ref="listComponent"></List>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import List from '@/components/List.vue'
import BackIcon from '@/components/BackIcon.vue'
import SearchForm from '@/components/SearchForm.vue'
//import FilterDomain from "@/components/FilterDomain.vue";

export default {
  name: 'TicketsSecondLevel',

  data() {
    return {
      spinner: false,
      tickets: [],
      status: '',
      totalTickets: 0,
      label: '',
      params: {},
      source: 'general'
    }
  },

  watch: {
    '$route.params.status': function(status) {
      this.loadTickets(status)
      this.status = status
      window.scrollTo(0, 0)
    }
  },

  mounted() {
    let status = 'assigned'
    if (this.$route.params.status != undefined) {
      status = this.$route.params.status
    }
    this.loadTickets(status)
    this.status = status
  },

  methods: {
    loadTickets(status) {
      this.params = { ...this.params, status: status }
      this.spinner = true
      axios
        .get('/second-level/tickets', {
          params: this.params
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.source = 'general'
            this.label = res.data.label
            this.tickets = res.data.tickets
            this.totalTickets = res.data.totalTickets
            this.$store.state.pageTicketList = res.data.tickets
          } else {
            //this.$swal("Ooops!", res.data.message, "error");
            this.tickets = []
            this.totalTickets = 0
            this.label = res.data.label
          }
          this.spinner = false
        })
    },

    searchTickets(data) {
      if (this.source === 'general') {
        this.source = 'search'
      }
      this.label = data.label
      this.tickets = data.tickets
      this.totalTickets = data.tickets.length
      this.$store.state.pageTicketList = data.tickets
    },

    pagination(data) {
      this.params = data
      if (this.source === 'general') {
        this.loadTickets(this.status) //this.loadTickets(this.status)
      }
      if (this.source === 'search') {
        this.$refs.searchForm.submitHandler()
      }
    },

    paginationReset() {
      this.$refs.listComponent.cleanUpPagniationData() // reset pagination to page 1
      this.params = {} // reset params
    }
  },

  components: {
    List,
    // ChatAuthor,
    BackIcon,
    SearchForm
    //FilterDomain,
  }
}
</script>

<style></style>
