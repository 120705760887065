<template>
  <div class="container">
    <!-- <div class="row">
      <div class="col-10 offset-1">
        <div class="card mb-3">
          <div class="card-header">Header</div>
          <div class="card-body">
            <p>Body</p>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {}
  },

  mounted() {
    this.spinner = true
    let verifiable = this.$route.params.verifiable
    let token = this.$route.params.token
    //let verifiable = this.$route.query.verifiable;
    //let token = this.$route.query.token;
    //console.log(verifiable);
    //console.log(token);

    let form = new FormData()
    form.append('token', token)
    form.append('verifiable', verifiable)

    axios.post('/auth/verifiable', form).then(res => {
      if (res.data.status == 'success') {
        this.$swal('', res.data.message, 'success')
        this.$router.push('/login')
      } else {
        this.$swal('', res.data.message, 'error')
      }
      this.spinner = false
    })
  }
}
</script>
