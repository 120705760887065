<template>
  <div id="CategoryModal" style="display: inline">
    <a href="#" v-b-modal="modalID" title="Change Category">Category</a>

    <!-- arrow-right -->
    <b-modal :id="modalID" title="Change Category" size="sm" hide-footer no-close-on-backdrop>
      <form @submit.prevent="submit">
        <!-- category -->
        <div class="form-group">
          <label for="category">Category of issues</label>
          <select @change="topSelected($event)" class="form-control" :class="{ 'is-invalid': $v.category.$error, 'is-valid': !$v.category.$invalid }">
            <option value="Select..." selected>Select...</option>
            <option v-for="(item, index) in categories" :key="index" :value="index">{{ item.name }}</option>
          </select>
          <div class="invalid-feedback">
            <span v-if="!$v.category.required">Category of issues is required.</span>
          </div>
        </div>

        <div class="form-group">
          <label for="subcategory">Subcategory</label>
          <select class="form-control" v-model="subcategory" :class="{ 'is-invalid': $v.subcategory.$error, 'is-valid': !$v.subcategory.$invalid }">
            <option v-for="(item, index) in subCategories" :key="index" :value="item.uuid">{{ item.name }}</option>
          </select>
          <div class="invalid-feedback">
            <span v-if="!$v.subcategory.required">Subcategory of issues is required.</span>
          </div>
        </div>

        <button v-if="!spinner" class="btn btn-warning" type="submit"><i class="far fa-paper-plane mr-1"></i> Submit</button>
        <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CategoryModal",

  data() {
    return {
      spinner: false,
      modalID: "CategoryModal",
      category: "",
      subcategory: "",

      categories: [],
      subCategories: [],
    };
  },

  props: {
    ticket: { type: Object },
  },

  mounted() {
    axios.get("/categories").then((res) => {
      if (res.data.status == "success") {
        this.categories = res.data.categories;
      }
      this.spinner = false;
    });
  },

  methods: {
    submit() {
      this.spinner = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.spinner = false;
        return;
      }

      let formData = new FormData();
      formData.append("categoryUUID", this.category);
      formData.append("subcategoryUUID", this.subcategory);
      formData.append("ref", this.ticket.ref);

      axios.post("/first-level/change-category", formData).then((res) => {
        if (res.data.status == "success") {
          this.$swal("Category", res.data.message, "success");
          this.$emit("categoryChangeSuccess", res.data.cat);
          this.$bvModal.hide(this.modalID);
        } else {
          this.$swal("Ooops!", res.data.message, "error");
        }
        this.spinner = false;
      });
    },

    topSelected(e) {
      if (e.target.value == "Select...") {
        return;
      }
      this.subCategories = this.categories[e.target.value].subCategories;
      this.category = this.categories[e.target.value].uuid;
    },
  },

  computed: {
    client() {
      return this.$store.state.client;
    },
  },

  validations: {
    category: { required },
    subcategory: { required },
  },
};
</script>

<style scoped>
.modal-body {
}
.col {
  height: 450px;
  overflow: auto;
  background-color: #eee;
}
.secondCol {
  border-left: 3px solid #ccc;
  border-right: 3px solid #ccc;
}
.list-group {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.escalateButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}
.list-group-item {
  font-size: 0.8rem;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4eadea;
  border-color: #4eadea;
}
</style>
