<template>
  <div id="AssignDepartmentAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Assign</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">{{ department.name }}</div>

          <div class="card-body">
            <!--  -->

            <form class="form-inline mb-5" @submit.prevent="assignUser">
              <div class="form-group mb-2">
                <input type="text" readonly class="form-control-plaintext" id="staticEmail2" value="Assign new user" />
              </div>
              <div class="form-group mx-sm-3 mb-2">
                <select class="form-control" id="exampleFormControlSelect1" v-model="selectedUserUUID" style="width:20rem; max-width:20rem">
                  <option>Select a user...</option>
                  <option v-for="(u, i) in unAssignedUsers" :key="i" :value="u.uuid">{{ u.name }} - {{ u.email }}</option>
                </select>
              </div>
              <button type="submit" class="btn btn-primary mb-2">Assign</button>
            </form>

            <!--  -->

            <!--Users Assigned-->
            <b>Assigned Users</b>
            <vue-good-table
              :columns="columns"
              :rows="assignedUsers"
              :line-numbers="true"
              :search-options="{
                enabled: true,
                trigger: 'enter',
              }"
              :pagination-options="{
                enabled: true,
                perPage: 50,
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: [{ field: 'name', type: 'asc' }],
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'name'">{{ props.row.name }}</span>

                <span v-if="props.column.field == 'email'">{{ props.row.email }}</span>

                <span v-if="props.column.field == 'unassign'">
                  <button @click="unassignUser(props.row.uuid, props.row.originalIndex)" class="btn btn-xs btn-outline-secondary" title="unassign">
                    unassign
                  </button>
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AssignDepartmentAdmin",

  data() {
    return {
      department: {},
      assignedUsers: [],
      unAssignedUsers: [],
      selectedUserUUID: "",
    };
  },

  mounted() {
    let loading = this.$loading.show();
    axios.get(`/admin/department/${this.$route.params.uuid}`).then((res) => {
      this.department = res.data.department;
      this.assignedUsers = res.data.department.users;
      this.unAssignedUsers = res.data.secondLevelUsers;

      // subtract the assigned from unassigned users
      const arrayOne = res.data.secondLevelUsers;
      const arrayTwo = res.data.department.users;
      const results = arrayOne.filter(({ uuid: id1 }) => !arrayTwo.some(({ uuid: id2 }) => id2 === id1));

      this.unAssignedUsers = results;
      /*  */

      loading.hide();
    });
  },

  methods: {
    assignUser() {
      let loading = this.$loading.show();
      this.$v.$touch();
      if (this.$v.$invalid) {
        loading.hide();
        return;
      }
      let formData = new FormData();
      formData.append("userUUID", this.selectedUserUUID);
      formData.append("departmentUUID", this.department.uuid);

      axios.post("admin/department/assign-user", formData).then((res) => {
        if (res.data.status == "success") {
          this.$swal("", `Assigned successfully`, "success");
          // add to assigned users array
          this.assignedUsers.push(res.data.user);
          // deduct from unassigned users array
          let selectedUserUUID = this.selectedUserUUID;
          this.unAssignedUsers = this.unAssignedUsers.filter(function(el) {
            return el.uuid != selectedUserUUID;
          });
          // Empty the selectedUserUUID
          this.selectedUserUUID = "";
          //this.$router.push({ name: "DepartmentsAdmin" });
        } else {
          this.$swal("", res.data.message, "error");
        }
        loading.hide();
      });
    },

    unassignUser(userUUID, index) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: `Unassign user ${this.department.users[index].name} from ${this.department.name} department.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, do it!",
        })
        .then((result) => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show();

            let formData = new FormData();
            formData.append("userUUID", userUUID);
            formData.append("departmentUUID", this.department.uuid);

            axios.post("/admin/department/unassign-user", formData).then((res) => {
              if (res.data.status == "success") {
                // Add unassigned user array
                this.unAssignedUsers.push(res.data.user);
                // Deduct from assigned user array
                this.assignedUsers.splice(index, 1);
                // pop notications
                this.$swal.fire("Unassigned!", `${this.department.users[index].name} from ${this.department.name} department.`, "success");
              } else {
                this.$swal("", res.data.message, "error");
              }
              loading.hide();
            });
            /* end of actural action */
          }
        });
    },
  },

  computed: {
    columns() {
      let cols = [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "",
          field: "unassign",
          sortable: false,
        },
      ];
      return cols;
    },

    client() {
      return this.$store.state.client;
    },
  },

  created() {
    //this.$store.commit("SET_LAYOUT", "agent-layout");
  },

  validations: {
    selectedUserUUID: { required },
    //description: { minLength: minLength(2) }
  },
};
</script>
