<template>
  <span>
    <button v-if="ticket.status.slug != 'resolved'" class="btn btn-sm btn-primary m-2" v-b-modal="modalID">
      {{ assignTitle }}
    </button>
    <!-- Assign or ReAssign Modal -->
    <b-modal :id="modalID" :ref="modalID" :title="`${assignTitle} ticket to a user`" size="md" hide-footer no-close-on-backdrop @shown="modalShown">
      <form @submit.prevent="assign">
        <!-- User -->
        <div class="form-group">
          <label for="user">Select a user</label>
          <select class="form-control" v-model="individualUUID" :class="{ 'is-invalid': $v.individualUUID.$error, 'is-valid': !$v.individualUUID.$invalid }">
            <option placeholder="Select..." selected>Select...</option>
            <option v-for="(usr, i) in users" :key="i" :value="usr.uuid">{{ usr.name }} - {{ usr.email }}</option>
          </select>
          <div class="invalid-feedback">
            <span v-if="!$v.individualUUID.required">Select a user.</span>
          </div>
        </div>

        <!-- Comment -->
        <div class="form-group">
          <label for="comment">Add a comment</label>
          <textarea class="form-control" v-model="comment" :class="{ 'is-invalid': $v.comment.$error, 'is-valid': !$v.comment.$invalid }" />
          <div class="invalid-feedback">
            <span v-if="!$v.comment.required">Comment required.</span>
          </div>
        </div>

        <button v-if="!spinner" class="btn btn-primary" type="submit">{{ assignTitle }}</button>
        <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
      </form>
    </b-modal>
    <!-- END Assign or ReAssign Modal -->
  </span>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AssignIndvModal',

  props: {
    path: String,
    ticket: Object
  },

  data() {
    return {
      modalID: 'assignIndvModal',
      comment: '',
      individualUUID: '',
      spinner: false,
      users: []
    }
  },

  /*   mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      //console.log('Modal is about to be shown', bvEvent, modalId)
    })
  }, */

  validations: {
    comment: { required },
    individualUUID: { required }
  },

  methods: {
    modalShown(e) {
      this.spinner = true
      axios.get('/user/level-users').then(res => {
        if (res.data.status == 'success') {
          //this.ticket.status = res.data.newStatus;
          this.users = res.data.users
          //console.log(res.data.users)
        } else {
          this.$swal('Oops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    },

    assign() {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      let formData = new FormData()
      formData.append('status', 'assigned')
      formData.append('ref', this.ticket.ref)
      formData.append('comment', this.comment)
      formData.append('individualUUID', this.individualUUID)
      axios.post('/assign-to-individual', formData).then(res => {
        if (res.data.status == 'success') {
          //this.ticket.status = res.data.newStatus;
          this.$refs[this.modalID].hide()
          this.$emit('newStatus', { newStatus: res.data.newStatus, newStatusLog: res.data.newStatusLog })
          this.$swal('Done!', res.data.message, 'success')
        } else {
          this.$swal('Oops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  },

  computed: {
    assignTitle() {
      let t = 'Assign (Indv)'
      if (this.ticket.status.slug == 'assigned') {
        t = 'Re-Assign (Indv)'
      }
      return t
    }
  }
}
</script>

<style></style>
