<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-header"><BackIcon /> <b-icon-stickies></b-icon-stickies> My Tickets {{ totalTickets | thousandSeparator }}</div>
          <div class="card-body">
            <List :tickets="tickets" :totalTickets="totalTickets" routeName="TicketDetailsUser" @pagination="pagination" ref="listComponent"></List>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import List from '@/components/List.vue'
import BackIcon from '@/components/BackIcon.vue'

export default {
  name: 'TicketsUser',

  data() {
    return {
      spinner: false,
      tickets: [],
      status: '',
      totalTickets: 0,
      params: {}
    }
  },

  watch: {
    '$route.params.status': function(status) {
      this.loadTickets(status)
      this.status = status
      window.scrollTo(0, 0)
    }
  },

  mounted() {
    let status = 'all'
    if (this.$route.params.status != undefined) {
      status = this.$route.params.status
    }
    this.loadTickets(status)
    this.status = status
  },

  methods: {
    loadTickets(status) {
      this.params = { ...this.params, status: status }
      this.spinner = true
      axios
        .get('/user/tickets', {
          params: this.params
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.tickets = res.data.tickets
            this.totalTickets = res.data.totalTickets
          } else {
            //this.$swal("Ooops!", res.data.message, "error");
            this.tickets = []
          }
          this.spinner = false
        })
    },

    pagination(data) {
      this.params = data
      this.loadTickets(this.status)
    }
  },

  components: {
    List,
    // ChatAuthor,
    BackIcon
  }
}
</script>

<style></style>
