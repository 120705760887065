<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <TicketNextPrev routeName="TicketDetailsSecondLevel" :status="ticket.status.name" />
        <div class="card">
          <div class="card-header"><BackIcon /> <b-icon-sticky v-if="!$routerHistory.hasPrevious()"></b-icon-sticky> Ticket Ref# {{ this.$route.params.ref }}</div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <table class="table table-sm">
                  <tbody>
                    <tr>
                      <th>Status:</th>
                      <td>
                        <TicketStatusBadge :status="ticket.status.name" />
                        <CountDown :ticket="ticket" />
                      </td>
                    </tr>
                    <tr>
                      <th>Category:</th>
                      <td>{{ ticket.category.name }}</td>
                    </tr>
                    <tr>
                      <th>Subcategory:</th>
                      <td>{{ ticket.subCategory.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <table class="table table-sm">
                  <tbody>
                    <tr>
                      <th>Source:</th>
                      <td>{{ ticket.source | titleCase }}</td>
                    </tr>
                    <tr>
                      <th>Priority:</th>
                      <td>
                        {{ ticket.priority | titleCase }}
                      </td>
                    </tr>
                    <tr>
                      <th>Created At:</th>
                      <td>{{ ticket.updatedAt | formatDateTime }}</td>
                    </tr>
                    <tr v-if="ticket.status.slug == 'assigned'">
                      <th>Department:</th>
                      <td>{{ ticket.department.name }}</td>
                    </tr>
                    <!-- <tr>
                      <th>Counter:</th>
                      <td>
                        <CountDown :ticket="ticket" />
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Status buttons -->
            <div class="row">
              <div class="col-md-12 text-center">
                <!-- Treated -->
                <TreatedModal :ticket="ticket" @newStatus="newStatus" path="second-level" />
                <!-- Treated -->

                <!-- Resolve -->
                <!-- <ResolveModal :ticket="ticket" @newStatus="newStatus" path="second-level" /> -->
                <!-- Resolve -->

                <!-- Assigned  -->
                <AssignModal :ticket="ticket" :departments="departments" @newStatus="newStatus" path="second-level" />
                <!-- Assigned  -->
                <!-- Assigned Individual  -->
                <AssignIndvModal :ticket="ticket" @newStatus="newStatus" />
                <!-- Assigned Individual -->

                <!-- Reopen -->
                <ReopenModal :ticket="ticket" @newStatus="newStatus" path="second-level" />
                <!-- Reopen -->

                <!-- Comment -->
                <CommentsModal :ticket="ticket" />
                <!-- Comment -->

                <!-- History -->
                <ChangeLogModal :ticket="ticket" />
                <!-- History -->
              </div>
            </div>
          </div>
        </div>
        <!-- Timeline -->
        <div class="card">
          <!-- <div class="card-header">ddd</div> -->
          <div class="card-body">
            <ul class="timeline">
              <li>
                <i class="fa fa-envelope bg-dark"><b-icon-envelope></b-icon-envelope></i>
                <div class="timeline-item">
                  <span class="time">
                    <b-icon-clock></b-icon-clock>
                    {{ ticket.updatedAt | formatDateTime }}
                  </span>
                  <h3 class="timeline-header">
                    <router-link :to="{ name: 'UserDetailsSecondLevel', params: { uuid: ticket.user.uuid } }" class="text-primary">{{ ticket.user.name }}'s</router-link>
                    message
                  </h3>
                  <div class="timeline-body">
                    <h4>{{ ticket.title }}</h4>
                    <iframe v-if="ticket.source == 'email'" :srcdoc="ticket.body" width="100%" height="500"></iframe>
                    <div v-else v-html="ticket.body"></div>
                  </div>
                  <div class="timeline-footer" v-if="ticket.attachments.length > 0">
                    <span>Attachment(s)</span>
                    <br />
                    <div v-for="(attachment, index) in ticket.attachments" :key="attachment.uuid">
                      <a :href="attachment.name" class="text-danger" target="_blank">
                        <b-icon-paperclip></b-icon-paperclip>
                        <span v-if="attachment.original_name !== ''">{{ attachment.original_name }}</span>
                        <span v-else>Download attachment {{ index + 1 }}</span>
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              </li>

              <!-- THREAD  -->
              <li v-for="thread in ticket.threads" :key="thread.uuid">
                <i v-if="thread.user.email == ticket.user.email" class="fa fa-share bg-danger"><b-icon-arrow90deg-left></b-icon-arrow90deg-left></i>
                <i v-if="thread.user.email != ticket.user.email" class="fa fa-reply bg-primary"><b-icon-arrow90deg-right></b-icon-arrow90deg-right></i>

                <div class="timeline-item">
                  <span class="time">
                    <b-icon-clock></b-icon-clock>
                    {{ thread.updatedAt | formatDateTime }}
                  </span>
                  <h3 class="timeline-header">
                    <span v-if="thread.user.email == ticket.user.email">
                      <router-link :to="{ name: 'UserDetailsSecondLevel', params: { uuid: thread.user.uuid } }" class="text-primary">{{ thread.user.name }}'s </router-link>
                      response
                    </span>
                    <span v-else-if="thread.user.email != ticket.user.email">
                      <span class="text-success">{{ thread.user.name }}'s</span> response
                    </span>
                  </h3>
                  <div class="timeline-body">
                    <iframe v-if="ticket.source == 'email'" :srcdoc="thread.body" width="100%" height="250"></iframe>
                    <div v-else v-html="thread.body"></div>
                  </div>
                  <div class="timeline-footer" v-if="thread.attachments.length > 0">
                    <span>Attachment(s):</span>
                    <br />
                    <div v-for="(attachment, index) in thread.attachments" :key="attachment.uuid">
                      <a :href="attachment.name" class="text-danger" target="_blank">
                        <b-icon-paperclip></b-icon-paperclip>
                        <span v-if="attachment.original_name !== ''">{{ attachment.original_name }}</span>
                        <span v-else>Download attachment {{ index + 1 }}</span>
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              </li>

              <!-- Comment FORM -->
              <!-- <li>
                <i class="far fa-paper-plane bg-dark"></i>
                <div class="timeline-item">
                  <h3 class="timeline-header">Comment</h3>
                  <div class="timeline-body">
                    <CommentForm :userEmail="authUser.Email" :ticketRef="ticket.ref" path="/second-level" @newComment="newComment"></CommentForm> 
                  </div>
                </div>
              </li> -->

              <li>
                <i class="fa fa-clock bg-dark"><b-icon-clock></b-icon-clock></i>
              </li>
            </ul>
          </div>
        </div>
        <!-- End of timeline -->
      </div>
    </div>

    <!-- Assign or ReAssign Modal -->
    <!--   <b-modal id="assignModal" :title="`${assignTitle} ticket to a department`" size="md" hide-footer no-close-on-backdrop>
      <form>
        <div class="form-group">
          <label for="subCategory">Department</label>
          <select
            class="form-control"
            v-model="assignDepartmentUUID"
            :class="{ 'is-invalid': $v.assignDepartmentUUID.$error, 'is-valid': !$v.assignDepartmentUUID.$invalid }"
          >
            <option placeholder="Select..." selected>Select...</option>
            <option v-for="(dept, i) in departments" :key="i" :value="dept.uuid">{{ dept.name }}</option>
          </select>
          <div class="invalid-feedback">
            <span v-if="!$v.assignDepartmentUUID.required">Select a department.</span>
          </div>
        </div>
        <button v-if="!spinner" class="btn btn-primary" type="submit" @click="changeStatus('assigned')">{{ assignTitle }}</button>
        <b-spinner v-if="spinner" variant="primary" label="Spinning"></b-spinner>
      </form>
    </b-modal> -->
    <!-- END Assign or ReAssign Modal -->
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'
//import CommentForm from "../../components/CommentForm";
import { required } from 'vuelidate/lib/validators'
import CommentsModal from '@/components/CommentsModal.vue'
import AssignModal from '@/components/AssignModal.vue'
import ReopenModal from '@/components/ReopenModal.vue'
import TreatedModal from '@/components/TreatedModal.vue'
import ResolveModal from '@/components/ResolveModal.vue'
import ChangeLogModal from '@/components/ChangeLogModal.vue'
import TicketStatusBadge from '@/components/TicketStatusBadge.vue'
import CountDown from '@/components/CountDown.vue'
import TicketNextPrev from '@/components/TicketNextPrev.vue'
import AssignIndvModal from '@/components/AssignIndvModal.vue'

export default {
  name: 'TicketDetailsSecondLevel',

  data() {
    return {
      ticket: {
        updatedAt: '',
        status: { name: '', slug: '' },
        category: { name: '' },
        subCategory: { name: '' },
        user: { name: '' },
        attachments: [],
        threads: []
      },
      departments: [],
      assignDepartmentUUID: '',
      //assignTitle: "Assign",
      spinner: false
    }
  },

  watch: {
    '$route.params.ref': function(ref) {
      this.loadTicket(ref)
      window.scrollTo(0, 0)
    }
  },

  mounted() {
    this.loadTicket(this.$route.params.ref)
    window.scrollTo(0, 0)
  },

  methods: {
    loadTicket(ref) {
      this.spinner = true
      axios
        .get('/second-level/ticket/' + ref)
        .then(res => {
          if (res.data.status == 'success') {
            this.ticket = res.data.ticket
            this.departments = res.data.departments
            //res.data.ticket.status.slug === "assigned" ? (this.assignTitle = "Re-Assign") : (this.assignTitle = "Assign");
          } else {
            this.$swal('Ooops!', res.data.message, 'error')
            this.$router.push({ name: 'Home' })
          }
          this.spinner = false
        })
        .catch(error => {})
    },

    /* newComment(e) {
      this.ticket.comments.push(e);
    }, */

    newStatus(data) {
      this.ticket.status = data.newStatus
      this.ticket.statusLog.push(data.newStatusLog)
    },

    customEscalateSuccess(newStatus) {
      this.ticket.status = newStatus
    },
    priorityChangeSuccess(priority) {
      this.ticket.priority = priority
    },
    categoryChangeSuccess(cat) {
      this.ticket.category.name = cat.categoryName
      this.ticket.subCategory.name = cat.subcategoryName
    }
  },

  computed: {
    topCategoryLabel() {
      return this.$store.state.client.topCategoryLabel
    },
    subCategoryLabel() {
      return this.$store.state.client.subCategoryLabel
    },
    authUser() {
      return this.$store.state.authUser
    },
    assignTitle() {
      let t = 'Assign'
      if (this.ticket.status.slug == 'assigned') {
        t = 'Re-Assign'
      }
      return t
    }
  },

  components: {
    //CommentForm,
    BackIcon,
    TicketStatusBadge,
    CommentsModal,
    AssignModal,
    ReopenModal,
    ResolveModal,
    ChangeLogModal,
    CountDown,
    TreatedModal,
    TicketNextPrev,
    AssignIndvModal
  },

  validations: {
    assignDepartmentUUID: { required }
  }
}
</script>

<style scoped>
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
</style>
