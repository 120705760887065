<template>
  <div id="DeleteFAQ"></div>
</template>

<script>
import axios from "axios";

export default {
  name: "DeleteFAQ",

  props: {
    faqIndex: Number,
    faqObject: {}
  },

  mounted() {
    this.$swal
      .fire({
        title: "Are you sure?",
        text: "DELETE: " + this.faqObject.question,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      })
      .then(result => {
        if (result.value) {
          /* actural action */
          let loading = this.$loading.show();
          let formData = new FormData();
          formData.append("uuid", this.faqObject.uuid);
          axios.post("/admin/faq/delete", formData).then(res => {
            if (res.data.status == "success") {
              this.$emit("deleted", { deletedFaqIndex: this.faqIndex });
              this.$swal.fire("Deleted!", res.data.message, "success");
            } else {
              this.$swal("", res.data.message, "error");
            }
            loading.hide();
          });
          /* end of actural action */
        }
      });
  }
};
</script>