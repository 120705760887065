<template>
  <div id="UserDetailsAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Assign second level support user</div>
      </div>
    </div>
    <main>
      <div class="container-fluid mt-n10">
        <div class="row">
          <div class="col-md-12">
            <div class="card mb-4">
              <div class="card-header">Assign {{ user.name }}</div>
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <label>Categories</label>
                    <select v-model="choosenCat" class="form-control">
                      <option value>Select...</option>
                      <option v-for="(cat, index) in categories" :key="index" :value="cat.uuid">{{ cat.name }}</option>
                    </select>
                    <button @click="assign" class="btn btn-danger mt-4">Assign</button>
                  </div>

                  <div class="col"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>


<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "UserDetailsAdmin",

  data() {
    return {
      user: { role: {} },
      categories: [],
      choosenCat: "",
    };
  },

  mounted() {
    let loading = this.$loading.show();
    axios.get(`/admin/user/${this.$route.params.uuid}`).then((res) => {
      this.user = res.data.user;
      loading.hide();
    });
    axios.get("/admin/categories").then((res) => {
      this.categories = res.data.categories;
      loading.hide();
    });
  },

  methods: {
    changeTopCategory(e) {
      this.subCategories = this.categories[e.target.value].subCategories;
    },

    assign() {
      let formData = new FormData();
      formData.append("userUUID", this.$route.params.uuid);
      formData.append("categoryUUID", this.choosenCat);
      axios.post("admin/assign-supervisor", formData).then((res) => {
        if (res.data.status == "success") {
          this.$swal("", res.data.message, "success");
          this.$router.push({ name: "UserDetailsAdmin", params: { uuid: this.$route.params.uuid } });
        }
      });
    },
  },

  computed: {
    client() {
      return this.$store.state.client;
    },
  },
  validations: {
    choosenSub: { required },
  },
};
</script>


<style scoped>
.mySelect {
  height: 400px !important;
}
</style>